<template>
  <div
  class="px-[13px] py-4 bottom-border-only cursor-pointer select-none flex gap-[14px] min-h-[140px]"
  :class="{
    'bg-[#02B2FF0A]': isActive || isUnread,
    'border-l-2 border-[#02B2FF]': isActive,
    }"
  @click="onClick"
  >
  <div class="mt-2" @click.stop>
    <Checkbox
      :model-value="isChecked"
      @update:model-value="onCheckboxToggle"
    />
  </div>
    <div class="flex-1 flex gap-[14px]">
      <!-- account image here -->
       <div>
         <div class="relative">
           <img
             :src="postBy?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
             :alt="postBy.name"
             class="w-8 h-8 rounded-full border"
             @error="
               (event) => {
                 event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
               }
             "
           />
           <div class="absolute -bottom-1 -right-1">
             <img
               :src="getSocialImageRounded(item?.platform)"
               class="w-5 h-5 rounded-full"
               :alt="item?.platform"
             />
           </div>
         </div>
       </div>
      <div class="flex-1 flex flex-col gap-[14px]">
        <div class="flex flex-1 justify-between">
        <div class="flex flex-col gap-[6px] w-[15.5rem]">
          <p class="text-sm font-semibold truncate" :class="{ 'opacity-70': !isUnread && !isActive }">{{ postBy.name }}</p>
          <p class="text-sm cstu-text-gray-500 line-clamp-2 truncate whitespace-pre-line" :class="{ 'opacity-70': !isUnread && !isActive }" >
            {{ itemDetails?.post_message }}
          </p>
        </div>
        <div class="flex flex-col justify-between items-end">
          <span
          :class="{
            'font-semibold text-sm': isUnread || isActive,
            'opacity-70': !isUnread && !isActive,
          }">{{ timeAgo }}</span>
          <template v-if="isUnread">
            <div class="pb-[10px]">
              <div class="rounded-full bg-[#02B2FF] text-white text-xs flex justify-center items-center w-[19px] h-[19px] font-medium">
                {{ unreadCount }}
              </div>
            </div>
          </template>
        </div>
        </div>
        <div class="flex items-center justify-between">
          <template v-if="showItemType">
            <span
              class="px-[10px] py-[4px] flex items-center gap-[8px] text-xs rounded-lg"
              :class="currentItemType.color"
             >
              <img :src="currentItemType.icon" alt="inbox type icon">
              {{ currentItemType.title }}
            </span>
          </template>
          <template v-else>
            <span></span>
          </template>
          <!-- last activity by photo -->
          <img
            class="w-8 h-8 rounded-full"
            src="https://i.pravatar.cc/150"
            alt="user"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Checkbox } from '@contentstudio/ui'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import useDateFormat from '@common/composables/useDateFormat'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const { momentWrapper } = useDateFormat()
const { getSocialImageRounded } = useComposerHelper()
const { inboxTypeChat, inboxTypeComment, inboxTypeReview } = useIcons()
const emit = defineEmits(['itemClick', 'checkboxToggle'])

const inboxItemType = {
  conversation: {
    title: 'Private Chat',
    color: 'bg-[#FD67271A]',
    icon: inboxTypeChat,
  },
  post: {
    title: 'Post Comments',
    color: 'bg-[#9747FF1A]',
    icon: inboxTypeComment,
  },
  review:{
    title: 'Reviews',
    color: 'bg-[#FFF7DE]',
    icon: inboxTypeReview,
  }
}

const props = defineProps({
  isUnread: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  item: {
    type: Object,
    required: true,
  },
  showItemType:{
    type: Boolean,
    default: true
  },
  isChecked: {
    type: Boolean,
    default: false,
  }
});

const itemDetails = computed(() => {
  return props.item?.element_details
})

const unreadCount = computed(() => {
  const count = props.item?.unread_message_count
  if (!count) return 0
  if (count > 9) return '9+'
  return count
})

const postBy = computed(() => {
  return props.item?.inbox_details?.posted_by
})

const currentItemType = computed(() => {
  return inboxItemType[props.item?.inbox_type] || inboxItemType.conversation
})

const timeAgo = computed(() => {
  return momentWrapper(props.item?.updated_at).utc(props.item?.updated_at).formatTimezone().fromNow();
})
const checkboxValue = ref(false)

const onClick = () => {
  emit('itemClick')
}

const onCheckboxToggle = (value) => {
  checkboxValue.value = value
  emit('checkboxToggle', value)
}
</script>
