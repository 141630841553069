<template>
  <div class="h-[4.25rem] bottom-border-only bg-white flex justify-between items-center px-6 py-4">
    <div class="flex items-center gap-3">
      <!-- <img
        :src="userDetails?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
        :alt="userDetails?.name"
        class="w-8 h-8 rounded-full border"
        @error="
          (event) => {
            event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          }
        "
      />
      <div class="flex flex-col gap-1">
        <span v-tooltip="userDetails?.name" class="font-semibold text-base max-w-[11rem] truncate">
          {{ userDetails?.name }}
        </span>
        <span v-tooltip="assignedToUser?.user_name" class="font-normal text-xs max-w-[11rem] truncate">
          {{ assignedToUser && isAssigned ? `Assigned to: ${assignedToUser?.user_name}` : 'Unassigned' }}
        </span>
      </div> -->
      <Dropdown
        class="!w-full"
        placement="bottom"
        size="sm"
        radius="lg"
        :disabled="isAssigning"
        >
        <template v-slot:selected>
          <div class="flex justify-between items-center">
            <span>
              <img
                :src="inboxAssignTo"
                class="mr-2"
                alt="assign"
              />
              {{ assignedToUser && isAssigned ? `Assigned to: ${assignedToUser?.user_name}` : 'Assign To' }}
            </span>
            <template v-if="isAssigning">
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </div>
        </template>
        <DropdownItem
          size="sm"
          class="flex items-center justify-between group"
          :selected="!isAssigned"
          @click="updateConversationsStatuses('assigned', false, null)"
        >
          <div class="flex items-center gap-2 ">
            <img
              :src="`https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
              alt="avatar"
              class="w-8 h-8 rounded-full"
            />
            Unassign
          </div>
          <img
            :src="inboxTickMark"
            alt="selected list icon"
            :class="{ 'hidden group-hover:block': isAssigned }"
          />
        </DropdownItem>
        <DropdownItem
          v-for="member in getters.getWorkspaceInboxTeamMembers"
          :key="member.user._id"
          size="sm"
          class="flex items-center justify-between group"
          :selected="isAssigned && member.user._id === assignedToUser?.user_id"
          @click="updateConversationsStatuses('assigned', true, member.user)"
        >
          <div class="flex items-center gap-2 ">
            <img
              :src="member.user?.image || `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
              alt="avatar"
              class="w-8 h-8 rounded-full"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            {{ member.user?.full_name }}
          </div>
          <img
            :src="inboxTickMark"
            alt="selected list icon"
            :class="{ 'hidden group-hover:block': member.user._id !== assignedToUser?.user_id }"
          />
        </DropdownItem>
      </Dropdown>
    </div>
    <div class="flex items-center gap-4">
      <div class="flex items-center gap-1">
        <template v-if="isArchived">
          <Button
            variant="subtle"
            color="warning"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="disabledBulkActions"
            @click="updateConversationsStatuses('archived', false, null)"
          >
            <img
              v-tooltip="'Unarchive'"
              :src="inboxArchived"
              alt="archive"
            />
            <span class="hidden xl:inline-block">
              Unarchive
            </span>
            <template v-if="isArchiving" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'12px'"
              ></clip-loader>
            </template>
          </Button>
        </template>
        <template v-else>
          <Button
            v-if="!isMarkedAsDone"
            variant="subtle"
            color="success"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="isMarkingAsDone"
            @click="updateConversationsStatuses('marked_done', true, null)"
          >
            <img
              :src="inboxMarkDone"
              alt="done"
            />
            Mark as Done
            <template v-if="isMarkingAsDone" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </Button>
          <Button
            v-else
            variant="subtle"
            color="success"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="isMarkingAsDone"
            @click="updateConversationsStatuses('marked_done', false, null)"
          >
            <img
              :src="inboxMarkDone"
              alt="done"
            />
            Mark as Undone
            <template v-if="isMarkingAsDone" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </Button>
        </template>
        <Dropdown
          v-if="!isMarkedAsDone && !isArchived"
          :unstyled="true"
          :show-arrow="false"
          button-classes="py-[12px] px-1"
          dropdown-classes="!min-w-[150px]"
          placement="bottom"
        >
          <template v-slot:selected>
            <i v-tooltip="'More'" class="far fa-ellipsis-v"></i>
          </template>

          <DropdownItem
            size="sm"
            :disabled="isArchiving"
            @click="updateConversationsStatuses('archived', true, null)"
          >
            <img
              :src="inboxArchived"
              class="mr-2"
              alt="archive"
            />
            Archive
            <template v-if="isArchiving" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </DropdownItem>
        </Dropdown>
      </div>
      <div>
        <!-- Tags Dropdown -->
        <Dropdown
          v-tooltip="'Tags'"
          placement="bottom"
          :show-arrow="false"
          dropdown-classes="w-72"
          size="sm"
          radius="lg"
          @close="handleSearchClose"
         >
          <template v-slot:selected>
            <template v-if="!currentConversation?.inbox_details?.tags?.length">
              <i class="far fa-tag cstu-text-gray-500 text-sm leading-none"></i>
              <span class="cstu-text-gray-500 text-sm font-normal">Tags</span>
            </template>
            <template v-else>
              <div class="flex items-center gap-2">
                <template v-for="(tag) in currentConversation.inbox_details.tags.slice(0, 2)" :key="tag._id">
                  <div class="flex items-center gap-1">
                    <TagIcon :class="`text_${tag?.tag_color}`" />
                    <span class=" hidden xl:inline-block text-sm truncate text-[#3A4557]">{{ tag.tag_name }}</span>
                  </div>
                </template>
                <div
                  v-if="currentConversation.inbox_details.tags.length > 2"
                  class="flex items-center justify-center w-[20px] h-[20px] rounded-full bg-[#FFC555] text-xs"
                  >
                  {{ `+${currentConversation.inbox_details.tags.length - 2}` }}
                </div>
              </div>
            </template>
          </template>
            <div>
              <div class="flex items-center justify-between">
                <div v-if="!isSearchActive && !searchText" class="flex items-center gap-3">
                  <span class="text-[#75797C] text-sm font-normal">Tags</span>
                  <Button
                    variant="text"
                    color="secondary"
                    size="sm"
                    class="!px-0"
                    @click="showCreateTag = !showCreateTag"
                  >
                  <img
                      v-if="!showCreateTag"
                      v-tooltip="'Add a new Tag'"
                      src="@src/assets/img/common/add-circle.svg"
                      class="h-5 w-5 cursor-pointer"
                      alt="add Tag"
                    />
                    <img
                      v-else
                      v-tooltip="'Add a new Tag'"
                      src="@src/assets/img/common/add-circle-disabled.svg"
                      class="h-5 w-5 cursor-pointer"
                      alt="add Tag disabled"
                    />
                  </Button>
                </div>
                <div
                  id="search"
                  :class="[
                    'ml-auto flex items-center px-3 gap-3 cstu-bg-gray-100 rounded-lg transition-all duration-300 h-[41px]',
                    isSearchActive || searchText ? 'flex-1' : 'w-[100px]',
                  ]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M6.52148 11.332C9.21387 11.332 11.3965 9.14942 11.3965 6.45703C11.3965 3.76464 9.21387 1.58203 6.52148 1.58203C3.8291 1.58203 1.64648 3.76464 1.64648 6.45703C1.64648 9.14942 3.8291 11.332 6.52148 11.332Z" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.4792 12.4167L10.3125 10.25" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <input
                    v-model="searchText"
                    type="text"
                    placeholder="Search"
                    class="w-full bg-transparent outline-none py-2.5"
                    @focus="isSearchActive = true"
                    @blur="handleSearchBlur"
                  />
                  <template v-if="isSearchActive">
                    <span class="cursor-pointer" @click="handleSearchClose">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.623 10L13.8428 6.78027C14.1357 6.4873 14.1357 6.0127 13.8428 5.71973C13.5498 5.42676 13.0752 5.42676 12.7822 5.71973L9.5625 8.93945L6.34277 5.71973C6.0498 5.42676 5.5752 5.42676 5.28223 5.71973C4.98926 6.0127 4.98926 6.4873 5.28223 6.78027L8.50195 10L5.28223 13.2197C4.98926 13.5127 4.98926 13.9873 5.28223 14.2803C5.42871 14.4268 5.62061 14.5 5.8125 14.5C6.00439 14.5 6.19629 14.4268 6.34277 14.2803L9.5625 11.0605L12.7822 14.2803C12.9287 14.4268 13.1206 14.5 13.3125 14.5C13.5044 14.5 13.6963 14.4268 13.8428 14.2803C14.1357 13.9873 14.1357 13.5127 13.8428 13.2197L10.623 10Z" fill="#4A4A4A"/>
                      </svg>
                    </span>
                  </template>
                </div>
                <div
                  v-tooltip="'Manage and edit your tags here.'"
                  class="ml-2 cursor-pointer font-normal text-gray-900 hover:text-primary-variant-1 transition-colors"
                  @click="redirectToSettings"
                  >
                  <i
                    class="icon-settings-cs h-9 w-9 text-xl text-center pt-1"
                  ></i>
                </div>
              </div>
              <transition name="fade">
                <template v-if="showCreateTag">
                  <LabeledColorInput
                    placeholder="Tag name"
                    :is-loading="isAddingTag"
                    @on-create="onCreateTags"
                    @on-cancel="onClose"
                  >
                    <template v-slot:prefix-icon>
                      <TagIcon class="text-[#3C4549]"/>
                    </template>
                  </LabeledColorInput>
                </template>
              </transition>
            </div>
            <hr class="m-0">
            <template v-if="filteredTags.length">
              <DropdownItem
                v-for="(item) in filteredTags"
                :key="item._id"
                :close-on-click="false"
                class="flex items-center gap-[14px] group select-none h-[30px] hover:font-medium"
                :class="{ '!font-medium': isTagSelected(item._id) }"
                :selected="isTagSelected(item._id)"
                :disabled="isTagLoading[item._id]"
                @click="ToggleTagSelection(item._id)"
              >
                <template v-slot>
                  <TagIcon :class="`text_${item?.tag_color}`" />
                  <p class="flex-1 text-sm truncate text-[#3A4557]">
                    {{ item.tag_name }}
                  </p>
                  <template v-if="isTagLoading[item._id]">
                    <clip-loader
                      class="align-middle inline-block"
                      color="#4165ed"
                      :size="'16px'"
                    ></clip-loader>
                  </template>
                  <template v-else>
                    <Checkbox :model-value="isTagSelected(item._id)" />
                  </template>
                </template>
              </DropdownItem>
            </template>
            <template v-else>
              <div class="px-4 py-2 text-sm cstu-text-gray-500">
                {{ searchText ? 'No tags match your search query' : 'No tags available' }}
              </div>
            </template>
          </Dropdown>
          <!-- Tags Dropdown End -->
        </div>
        <div>
          <DetailsDrawer />
        </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Button, Checkbox, Dropdown, DropdownItem } from '@contentstudio/ui';
import TagIcon from '@src/modules/inbox-revamp/components/TagIcon.vue'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons';
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox';
import { useStore } from '@state/base';
import { useRouter } from 'vue-router';
import { debounce } from 'lodash'
import DetailsDrawer from '@src/modules/inbox-revamp/components/DetailsDrawer.vue'
import LabeledColorInput from '@/src/components/common/LabeledColorInput.vue'

const router = useRouter()
const { getters } = useStore();

const { inboxMarkDone, inboxTickMark, inboxArchived, inboxAssignTo } = useIcons()

const {
  inboxTypes,
  selectedInboxType,
  isMarkingAsDone,
  isArchiving,
  isAssigning,
  currentConversation,
  tags,
  createWorkspaceTag,
  assignTagToInboxItem,
  assignInboxToUser,
  markAsDoneInboxItems,
  archiveInboxItems,
  fetchDetails,
  fetchConversationList
} = useInbox();

const showCreateTag = ref(false)
const isAddingTag = ref(false)
const isTagLoading = ref({})
const selectedTags = computed(() => {
  return currentConversation.value?.inbox_details?.tags;
});

// const userDetails = computed(() => {
//   return currentConversation.value?.inbox_details?.posted_by;
// });

const isMarkedAsDone = computed(() => {
  return currentConversation.value?.inbox_details?.marked_done?.is_marked_done;
});

const isArchived = computed(() => {
  return currentConversation.value?.inbox_details?.archived?.is_archived;
});

const isAssigned = computed(() => {
  return currentConversation.value?.inbox_details?.assigned?.is_assigned;
});

const assignedToUser = computed(() => {
  return currentConversation.value?.inbox_details?.assigned?.assigned_to;
});


const searchText = ref('');
const isSearchActive = ref(false);

const filteredTags = computed(() => {
  if (!searchText.value) return tags.value;
  return tags.value.filter(tag =>
    tag.tag_name.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

const fetchDetailsDebounced = debounce(async (value) => {
  fetchDetails(inboxTypes?.[value]?.filter)
  await fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, router?.currentRoute.value?.params?.filter)
}, 500)

const handleSearchBlur = () => {
  if (!searchText.value) {
    isSearchActive.value = false;
  }
};

const handleSearchClose = () => {
  searchText.value = '';
  isSearchActive.value = false;
};

const onCreateTags = async(data) => {
  isAddingTag.value = true
  await createWorkspaceTag(data)
  showCreateTag.value = false
  isAddingTag.value = false
}

const onClose = () => {
  showCreateTag.value = false
}

const isTagSelected = (tagId) => {
  return selectedTags.value.some((tag) => tag._id === tagId);
};

const ToggleTagSelection = async (tagId) => {
  isTagLoading.value[tagId] = true
  await assignTagToInboxItem(tagId, isTagSelected(tagId))
  isTagLoading.value[tagId] = false
}

const redirectToSettings = () => {
  router.push({ name: 'miscellaneous', hash: 'tags' })
}

const updateConversationsStatuses = async (action, value, user) => {
  const currentId = currentConversation.value?.element_details?.element_id
  switch (action) {
    case 'assigned':
      await assignInboxToUser(value, {
        user_id: user?._id || '',
        user_name: user?.full_name || '',
      }, [currentId])
      fetchDetailsDebounced(selectedInboxType.value)
      break
    case 'marked_done':
      await markAsDoneInboxItems(value, [ currentId ])
      fetchDetailsDebounced(selectedInboxType.value)
      break
    case 'archived':
      await archiveInboxItems(value, [ currentId ])
      fetchDetailsDebounced(selectedInboxType.value)
      break
    default:
      break
  }
}

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
