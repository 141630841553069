<template>
  <Dropdown
    placement="bottom"
    dropdown-classes="w-72 !max-h-[none]"
    size="sm"
    radius="lg"
    @close="handleSearchClose(true)"
  >
    <template v-slot:selected>
      <div class="flex gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clip-path="url(#clip0_35795_17755)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.31055 2.8125C4.06791 2.8125 3.06055 3.81987 3.06055 5.0625C3.06055 6.30514 4.06791 7.31251 5.31055 7.31251C6.55323 7.31251 7.56056 6.30514 7.56056 5.0625C7.56056 3.81987 6.55323 2.8125 5.31055 2.8125ZM1.56055 5.0625C1.56055 2.99144 3.23949 1.3125 5.31055 1.3125C7.38161 1.3125 9.06056 2.99144 9.06056 5.0625C9.06056 7.13356 7.38161 8.81251 5.31055 8.81251C3.23949 8.81251 1.56055 7.13356 1.56055 5.0625ZM14.8409 6.03217C15.1338 6.32507 15.1338 6.79996 14.8409 7.09283L11.8409 10.0928C11.548 10.3857 11.0731 10.3857 10.7802 10.0928L9.28023 8.59283C8.98736 8.29996 8.98736 7.82506 9.28023 7.53218C9.57311 7.23931 10.048 7.23931 10.3409 7.53218L11.3106 8.50186L13.7802 6.03217C14.0731 5.73928 14.548 5.73928 14.8409 6.03217ZM1.71254 10.5671C2.66622 9.93136 3.9405 9.56251 5.31055 9.56251C6.68058 9.56251 7.95491 9.93136 8.90853 10.5671C9.85698 11.1995 10.5606 12.1553 10.5606 13.3125C10.5606 13.7267 10.2248 14.0625 9.81056 14.0625C9.39633 14.0625 9.06056 13.7267 9.06056 13.3125C9.06056 12.8129 8.75673 12.2687 8.07648 11.8152C7.40148 11.3652 6.42581 11.0625 5.31055 11.0625C4.19532 11.0625 3.2196 11.3652 2.5446 11.8152C1.86434 12.2687 1.56055 12.8129 1.56055 13.3125C1.56055 13.7267 1.22476 14.0625 0.810548 14.0625C0.396337 14.0625 0.0605469 13.7267 0.0605469 13.3125C0.0605469 12.1553 0.764115 11.1995 1.71254 10.5671Z" fill="#4A4A4A"/>
          </g>
          <defs>
            <clipPath id="clip0_35795_17755">
              <rect width="15" height="15" fill="white" transform="translate(0.0625 0.5)"/>
            </clipPath>
          </defs>
        </svg>
      <template v-if="!selectedMembers.length">
          <span class="text-sm font-normal">{{ title }}</span>
      </template>
      <template v-else>
        <div class="flex items-center gap-2">
          {{ selectedMembers.length > 10 ? '10+' : selectedMembers.length }} Selected
          <!-- <template v-for="(member, index) in selectedMembers.slice(0, 2)" :key="member.user._id">
            <div class="flex items-center gap-1">
              <img
                :src="member.user?.image || defaultProfileImage"
                :alt="member.user?.full_name"
                class="w-6 h-6 rounded-full"
                @error="handleImageError"
              />
              <span class="hidden xl:inline-block text-sm truncate text-[#3A4557]">{{ member.user?.full_name }}</span>
            </div>
          </template>
          <div
            v-if="selectedMembers.length > 2"
            class="flex items-center justify-center w-[20px] h-[20px] rounded-full bg-[#FFC555] text-xs"
          >
            {{ `+${selectedMembers.length - 2}` }}
          </div> -->
        </div>
      </template>
    </div>
    </template>
    <template v-slot="{ closeDropdown }">
      <div>
        <div class="flex items-center justify-between h-[35px]">
          <div v-if="!isSearchActive && !searchText" class="flex items-center gap-3">
            <div class="ml-2.5 flex items-center gap-2">
              <Checkbox
                :model-value="isAllSelected"
                :indeterminate="isIndeterminate"
                @update:model-value="toggleSelectAll"
              />
              <span class="text-[#75797C] text-sm font-normal">Select All</span>
            </div>
          </div>
          <div
            ref="searchContainer"
            class="ml-auto flex items-center gap-3 rounded-lg transition-all duration-300"
            :class="[
              isSearchActive || searchText ? 'cstu-bg-gray-100 h-[35px] flex-1 px-3' : 'w-[32px] cursor-pointer',
            ]"
            @click="!isSearchActive && activateSearch()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M6.52148 11.332C9.21387 11.332 11.3965 9.14942 11.3965 6.45703C11.3965 3.76464 9.21387 1.58203 6.52148 1.58203C3.8291 1.58203 1.64648 3.76464 1.64648 6.45703C1.64648 9.14942 3.8291 11.332 6.52148 11.332Z" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.4792 12.4167L10.3125 10.25" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <input
              v-if="isSearchActive || searchText"
              ref="searchInput"
              v-model="searchText"
              type="text"
              placeholder="Search"
              class="w-full bg-transparent outline-none py-1.5"
              @focus="isSearchActive = true"
              @blur="handleSearchBlur"
            />
            <template v-if="isSearchActive || searchText">
              <span class="cursor-pointer" @click.stop="handleSearchClose">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10.623 10L13.8428 6.78027C14.1357 6.4873 14.1357 6.0127 13.8428 5.71973C13.5498 5.42676 13.0752 5.42676 12.7822 5.71973L9.5625 8.93945L6.34277 5.71973C6.0498 5.42676 5.5752 5.42676 5.28223 5.71973C4.98926 6.0127 4.98926 6.4873 5.28223 6.78027L8.50195 10L5.28223 13.2197C4.98926 13.5127 4.98926 13.9873 5.28223 14.2803C5.42871 14.4268 5.62061 14.5 5.8125 14.5C6.00439 14.5 6.19629 14.4268 6.34277 14.2803L9.5625 11.0605L12.7822 14.2803C12.9287 14.4268 13.1206 14.5 13.3125 14.5C13.5044 14.5 13.6963 14.4268 13.8428 14.2803C14.1357 13.9873 14.1357 13.5127 13.8428 13.2197L10.623 10Z" fill="#4A4A4A"/>
                </svg>
              </span>
            </template>
          </div>
        </div>
      </div>

      <hr class="m-0">
      <template v-if="filteredMembers.length">
        <div class="max-h-[40vh] overflow-y-auto space-y-2">
          <DropdownItem
            v-for="member in filteredMembers"
            :key="member.user._id"
            :close-on-click="false"
            class="flex items-center gap-[12px] group select-none h-[35px] hover:font-medium"
            :class="{ '!font-medium': isMemberSelected(member.user._id) }"
            :selected="isMemberSelected(member.user._id)"
            @click="toggleMemberSelection(member)"
          >
            <template v-slot>
              <Checkbox :model-value="isMemberSelected(member.user._id)" class="mr-2" />
              <img
                :src="member.user?.image || defaultProfileImage"
                :alt="member.user?.full_name"
                class="w-8 h-8 rounded-full"
                @error="handleImageError"
              />
              <p class="flex-1 text-sm truncate text-[#3A4557]">
                {{ member.user?.full_name }}
              </p>
            </template>
          </DropdownItem>
        </div>
      </template>
      <template v-else>
        <div class="px-4 py-2 text-sm cstu-text-gray-500">
          {{ searchText ? 'No team members match your search query' : 'No team members available' }}
        </div>
      </template>
      <div class="flex items-center gap-2 border-t pt-3">
        <Button
          :full-width="true"
          variant="light"
          color="secondary"
          size="xs"
          @click="closeDropdown"
        >
          Close
        </Button>
        <Button
          :full-width="true"
          size="xs"
          @click="apply(closeDropdown)"
        >
          Apply
        </Button>
      </div>
    </template>
  </Dropdown>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { Dropdown, DropdownItem, Checkbox, Button } from '@contentstudio/ui'

const props = defineProps({
  members: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    default: 'Title Here'
  }
})

const emit = defineEmits(['update:modelValue'])

const defaultProfileImage = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
const searchText = ref('')
const isSearchActive = ref(false)
const selectedMembers = ref([...props.modelValue])
const tempSelectedMembers = ref([...props.modelValue])

watch(() => props.modelValue, (newValue) => {
  selectedMembers.value = [...newValue]
  tempSelectedMembers.value = [...newValue]
})

const searchContainer = ref(null)
const searchInput = ref(null)

const isAllSelected = computed(() => {
  return filteredMembers.value.length > 0 && filteredMembers.value.every((member) => tempSelectedMembers.value.includes(member.user._id))
})

const isIndeterminate = computed(() => {
  return tempSelectedMembers.value.length > 0 && tempSelectedMembers.value.length < props.members.length
})

const toggleMemberSelection = (member) => {
  const memberId = member.user._id
  const index = tempSelectedMembers.value.findIndex((id) => id === memberId)
  if (index === -1) {
    tempSelectedMembers.value.push(memberId)
  } else {
    tempSelectedMembers.value.splice(index, 1)
  }
}

const toggleSelectAll = () => {
  if (isAllSelected.value) {
    tempSelectedMembers.value = []
  } else {
    tempSelectedMembers.value = filteredMembers.value.map((member) => member.user._id)
  }
}

const isMemberSelected = (memberId) => {
  return tempSelectedMembers.value.includes(memberId)
}

const filteredMembers = computed(() => {
  if (!searchText.value) return props.members

  const searchLower = searchText.value.toLowerCase()
  return props.members.filter(member =>
    member.user?.full_name?.toLowerCase().includes(searchLower)
  )
})

const handleSearchClose = (fromDropdownClose = false) => {
  isSearchActive.value = false
  searchText.value = ''
  if (fromDropdownClose) {
    tempSelectedMembers.value = [...selectedMembers.value]
  }
}

const handleSearchBlur = () => {
  if (!searchText.value) {
    isSearchActive.value = false
  }
}

const handleImageError = (event) => {
  event.target.src = defaultProfileImage
}

const activateSearch = () => {
  isSearchActive.value = true
  nextTick(() => {
    if (searchInput.value) {
      searchInput.value.focus()
    }
  })
}

const apply = (closeDropdown) => {
  selectedMembers.value = [...tempSelectedMembers.value]
  emit('update:modelValue', selectedMembers.value)
  closeDropdown()
}
</script>
