<template>
  <div
    class="flex gap-3 mt-2"
    :class="{
      'flex-row-reverse': isMessageMine,
      'opacity-50': message?.isMessageSending,
    }"
  >
    <template v-if="message?.error">
      <div class="h-[35px] flex items-center mt-1">
        <i v-tooltip="message?.errorMessage" class="fas fa-exclamation-triangle text-red-500"></i>
      </div>
    </template>
    <div v-tooltip="`${message?.from?.[0]?.first_name} ${message?.from?.[0]?.last_name}`" class="">
      <img
        :src="message?.from?.[0]?.profile_pic"
        alt=""
        class="w-[35px] h-[35px] rounded-full mt-1"
        :class="{ 'float-right': isMessageMine }"
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
    </div>
    <div class="space-y-1 flex-1">
      <template v-if="message?.attachments?.length > 0">
        <template v-if="message?.attachments?.[0]?.type === 'image'">
          <img
            :src="message?.attachments?.[0]?.image_url"
            alt=""
            class="rounded-3xl w-[250px] h-[250px] object-contain border"
            :class="{ 'float-right': isMessageMine }"
            @error="handleImageError"
          >
        </template>
        <template v-else-if="message?.attachments?.[0]?.type === 'share'">
          <img
            :src="message?.attachments?.[0]?.link"
            alt=""
            class="rounded-3xl w-[250px] h-[250px] object-contain border"
            :class="{ 'float-right': isMessageMine }"
            @error="handleImageError"
          >
        </template>
        <template v-else-if="message?.attachments?.[0]?.type === 'video'">
          <video
            :src="message?.attachments?.[0]?.video_url"
            controls
            class="rounded-3xl"
            :class="{ 'float-right max-w-[80%] lg:max-w-[60%] 2xl:max-w-[40%]': isMessageMine }"
          ></video>
        </template>
      </template>
      <template v-else>
        <div
          id="message"
          class="inline-block rounded-3xl px-3.5 py-3 max-w-[80%] lg:max-w-[60%] 2xl:max-w-[40%]"
          :class="{
            'bg-white border': !isMessageMine,
            'cstu-bg-blue-500 text-white cstu-border-blue-500 float-right': isMessageMine,
          }"
        >
          <template v-if="message?.is_unsupported">
            <span class="text-sm whitespace-pre-wrap" :class="{ 'italic': message?.is_unsupported }">
              This message may contain content types such as voice notes, stickers, GIFs, etc., which are currently unsupported due to API limitations.
            </span>
          </template>
          <template v-else>
            <HtmlRenderer
              v-if="message?.message?.length > 0"
              tag="span"
              class="text-sm whitespace-pre-wrap"
              :html-content="parsedMessage"
            />
          </template>
        </div>
      </template>
      <div>
        <span
          class="text-opacity-50 cstu-text-gray-500 text-xs"
          :class="{ 'float-right clear-both': isMessageMine }"
        >
          {{ formattedTime }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import useDateFormat from '@common/composables/useDateFormat'
import { parseDescriptionHtml } from '@common/lib/helper'

const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  isMessageMine: {
    type: Boolean,
    required: true
  }
})

const { momentWrapper } = useDateFormat()

const handleImageError = (event) => {
  event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
}

const parsedMessage = computed(() => {
  return parseDescriptionHtml(props.message?.message, props.message?.message?.length, true, false)
})

const formattedTime = computed(() => {
  return momentWrapper(props.message?.created_time).utc(props.message?.created_time).formatTimezone().formatTime()
})
</script>
