<template>
  <Button
    variant="text"
    :size="size"
    :disabled="loading"
    class="flex items-center border !border-transparent hover:!border-gray-200 transition-all"
    @click="$emit('click')"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="{ 'animate-spin': loading }"
    >
      <g clip-path="url(#clip0_32828_12617)">
        <path
          d="M2.84277 1.99109C3.1748 1.69812 3.53125 1.43933 3.91211 1.21472C4.2832 0.990112 4.67627 0.797241 5.09131 0.636108C5.50635 0.474976 5.93848 0.350464 6.3877 0.262573C6.83691 0.184448 7.30078 0.145386 7.7793 0.145386C8.81445 0.145386 9.78613 0.340698 10.6943 0.731323C11.6025 1.12195 12.396 1.65662 13.0747 2.33533C13.7534 3.01404 14.293 3.8075 14.6934 4.7157C15.084 5.63367 15.2793 6.61023 15.2793 7.64539C15.2793 8.45593 15.1548 9.2323 14.9058 9.97449C14.6567 10.7167 14.3125 11.3954 13.873 12.0106L13.8877 11.9813L11.5 7.66003H13.7559C13.7559 6.82996 13.5947 6.05359 13.2725 5.33093C12.96 4.59851 12.5327 3.9613 11.9907 3.41931C11.4487 2.87732 10.8115 2.45007 10.0791 2.13757C9.35645 1.82507 8.58008 1.66882 7.75 1.66882C6.93945 1.66882 6.17773 1.81775 5.46484 2.1156C4.75195 2.41345 4.12695 2.82117 3.58984 3.33875L2.84277 1.99109ZM12.6572 13.329C12.3252 13.6219 11.9688 13.8807 11.5879 14.1053C11.2168 14.33 10.8237 14.5228 10.4087 14.684C9.99365 14.8451 9.56152 14.9696 9.1123 15.0575C8.66309 15.1356 8.19922 15.1747 7.7207 15.1747C6.68555 15.1747 5.71387 14.9794 4.80566 14.5887C3.89746 14.1981 3.104 13.6635 2.42529 12.9847C1.74658 12.306 1.20703 11.5126 0.806641 10.6044C0.416016 9.6864 0.220703 8.70984 0.220703 7.67468C0.220703 6.86414 0.345215 6.08777 0.594238 5.34558C0.843262 4.60339 1.1875 3.92468 1.62695 3.30945L1.6123 3.33875L4 7.66003H1.74414C1.75391 8.49011 1.91504 9.26648 2.22754 9.98914C2.54004 10.7216 2.96729 11.3588 3.50928 11.9008C4.05127 12.4427 4.68848 12.87 5.4209 13.1825C6.14355 13.495 6.91992 13.6512 7.75 13.6512C8.55078 13.6512 9.30762 13.5023 10.0205 13.2045C10.7334 12.9066 11.3584 12.4989 11.8955 11.9813L12.6572 13.329Z"
          fill="#75797C"
        />
      </g>
      <defs>
        <clipPath id="clip0_32828_12617">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="matrix(1 0 0 -1 0.25 15.16)"
          />
        </clipPath>
      </defs>
    </svg>
  </Button>
</template>

<script setup>
import { Button } from '@contentstudio/ui'

defineEmits(['click'])

defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'xs'
  }
})

</script>
