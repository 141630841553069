<script setup>
import { computed } from 'vue'
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader.vue'
import CardsComponent from '@src/modules/analytics/views/linkedin_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/linkedin_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/linkedin_v2/components/graphs/AudienceGrowthChart.vue'
import PostDensityByDay from '@src/modules/analytics/views/linkedin_v2/components/graphs/PostDensityByDay.vue'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import FollowersDemographicsChart from '@src/modules/analytics/views/linkedin_v2/components/graphs/FollowersDemographics.vue'
import RollupCard from '@/src/modules/analytics/views/linkedin_v2/components/RollupCard.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/linkedin_v2/components/graphs/PublishingBehaviourChart.vue'
import PublishingBehaviourBreakdownTable from '@/src/modules/analytics/views/linkedin_v2/components/PublishingBehaviourBreakdownTable.vue'
import HashtagsChart from '@/src/modules/analytics/views/linkedin_v2/components/graphs/HashtagsChart.vue'
import PostsSection from '@/src/modules/analytics/views/linkedin_v2/components/PostsSection.vue'

const {
  routes,
  rollupTypes,
  topPostsData,
  overviewPublishingBehaviourData,
  isLoadingStates,
} = useLinkedinAnalytics()

// max no. of posts per page
const chunkSize = 10

/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []
  if (topPostsData.value) {
    for (let i = 0; i < topPostsData.value?.length; i += chunkSize) {
      startIndexes.push(i)
    }
  }
  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const TOTAL_SECTIONS = 6

  return TOTAL_SECTIONS + topEngagingPostsChunks.value.length
})

const breakdownTableData = computed(() => {
  return (
    overviewPublishingBehaviourData.value?.publishing_behaviour_rollup
      ?.current || []
  )
})
</script>

<template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Linkedin'"
        :current="1"
        :total="totalPageSize"
      ></ReportHeader>
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-14">
          <CardsComponent />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Audience Growth</h2>
        <div class="grid grid-cols-3 gap-4 mb-7">
          <div class="col-span-2">
            <AudienceGrowthChart type="cumulative" />
          </div>
          <RollupCard :type="rollupTypes.AUDIENCE" />
        </div>
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Linkedin'"
        :current="2"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-1">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Audience Growth</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <AudienceGrowthChart type="daily" />
          </div>
          <RollupCard :type="rollupTypes.AUDIENCE" />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Engagement</h2>
        <PublishingBehaviourChart type="engagements" />
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Linkedin'"
        :current="3"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Impressions</h2>
        <PublishingBehaviourChart type="impressions" />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Reach</h2>
        <PublishingBehaviourChart type="reach" />
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Linkedin'"
        :current="4"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-1">
        <!-- Publishing Behaviour Breakdown Table -->
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour Breakdown</h2>
        <PublishingBehaviourBreakdownTable
          :data-list="breakdownTableData"
          :is-loading="isLoadingStates[routes.OVERVIEW_PUBLISHING_BEHAVIOUR]"
          :is-report-view="true"
        />
        <!-- Hastags Chart -->
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Hashtags Performance</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <HashtagsChart />
          </div>
          <RollupCard :type="rollupTypes.HASHTAGS" />
        </div>
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Linkedin'"
        :current="5"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Post Density By Day</h2>
        <PostDensityByDay />
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Followers Demographics By City</h2>
        <FollowersDemographicsChart type="city" />
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Linkedin'"
        :current="6"
        :total="totalPageSize"
      ></ReportHeader>
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Followers Demographics By Country</h2>
          <FollowersDemographicsChart type="country" />
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Followers Demographics By Industry</h2>
        <FollowersDemographicsChart type="industry" />
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Followers Demographics By Seniority</h2>
          <FollowersDemographicsChart type="seniority" />
    </div>
  </div>
  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view">
      <div class="subpage">
        <!-- Render report header -->
        <ReportHeader
          :report-name="'Linkedin'"
          :current="7 + index"
          :total="totalPageSize"
        ></ReportHeader>
        <!-- Render post table for the current chunk of top posts -->
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Linkedin Top Posts (Top 5)</h2>
        <PostsSection
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, topPostsData?.length)"
        />
      </div>
    </div>
  </template>
</template>
