<template>
  <div>
    <Button
      v-tooltip="'Details'"
      variant="text"
      color="secondary"
      size="sm"
      radius="lg"
      class="!font-normal !cstu-text-gray-500 cstu-border cstu-border-gray-400/20 hover:cstu-bg-gray-100/50 hover:cstu-border-gray-400/25"
      @click="toggleFilterDrawer"
    >
      <i class="far fa-info-circle cstu-text-gray-500 text-sm leading-none"></i>
      <span class="hidden xl:inline-block">
        Details
      </span>
    </Button>
    <!-- Filter Drawer -->
    <CstDrawer
      :model-value="showFilterDrawer"
      title="Details"
      position="right"
      body-class="!overflow-visible"
      :width="290"
      @update:model-value="showFilterDrawer = $event"
    >
      <template v-slot:header="{ close }">
        <div class="h-[4.25rem] bottom-border-only flex items-center justify-between py-[16px] px-[14px]">
          <div class="flex items-center gap-[6px]" >
            <i class="far fa-info-circle cstu-text-gray-500 text-sm leading-none"></i>
            <span class="font-medium">Detailed Info</span>
          </div>
          <div class="cursor-pointer" @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
              <path d="M10.5605 9.5L13.7803 6.28027C14.0732 5.9873 14.0732 5.5127 13.7803 5.21973C13.4873 4.92676 13.0127 4.92676 12.7197 5.21973L9.5 8.43945L6.28027 5.21973C5.9873 4.92676 5.5127 4.92676 5.21973 5.21973C4.92676 5.5127 4.92676 5.9873 5.21973 6.28027L8.43945 9.5L5.21973 12.7197C4.92676 13.0127 4.92676 13.4873 5.21973 13.7803C5.36621 13.9268 5.55811 14 5.75 14C5.94189 14 6.13379 13.9268 6.28027 13.7803L9.5 10.5605L12.7197 13.7803C12.8662 13.9268 13.0581 14 13.25 14C13.4419 14 13.6338 13.9268 13.7803 13.7803C14.0732 13.4873 14.0732 13.0127 13.7803 12.7197L10.5605 9.5Z" fill="#4A4A4A"/>
            </svg>
          </div>
        </div>
      </template>
      <template v-slot>
        <div class="px-[13.5px] py-[11px] h-full overflow-y-auto">
          <div class="mb-4 cstu-bg-gray-100 py-4 px-3 rounded-xl space-y-5">
            <div class="flex items-center gap-3 bg-white p-4 rounded-xl border">
              <div class="relative">
                <img
                  :src="postBy?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                  :alt="postBy.name"
                  class="w-10 h-10 rounded-full border"
                  @error="
                    (event) => {
                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                    }
                  "
                />
                <div class="absolute -bottom-1 -right-1">
                  <img
                    :src="getSocialImageRounded(item?.platform)"
                    class="w-6 h-6 rounded-full"
                    :alt="item?.platform"
                  />
                </div>
              </div>
              <div class="flex-1 truncate">
                <p v-tooltip="postBy?.name" class="font-semibold truncate">{{ postBy?.name }}</p>
                <p class="text-sm cstu-text-gray-500 !text-opacity-50">Profile</p>
              </div>
              <Button
              v-tooltip="'Edit Profile'"
                variant="text"
                size="sm"
              >
                <img
                  :src="inboxEditProfile"
                  alt="edit-profile"
                />
              </Button>
            </div>
            <div class="space-y-3">
              <div
                v-for="(item, index) in detailsSection"
                :key="index"
                class="grid grid-cols-[auto,1fr] items-center gap-x-2"
              >
                <div class="flex items-center gap-3">
                  <img
                    :src="item.icon"
                    alt="icon"
                    class="w-5 h-5"
                  />
                  <span class="text-[#3A4557] font-bold min-w-[100px]">{{ item.title }}</span>
                </div>
                <input
                  v-model="userInfo[item.key]"
                  type="text"
                  class="border-0 bg-transparent px-3 py-1.5 w-full"
                  :placeholder="'Enter ' + item.title.toLowerCase()"
                />
              </div>
            </div>
          </div>
          <Collapsible :default-open="true">
            <template v-slot:trigger="{ open }">
              <div class="flex items-center gap-3 group select-none">
                <span class="text-[#3A4557] font-normal">Bookmarks</span>
                <div
                    class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                    :class="{
                    '!bg-[#D8EFFF] !font-medium': open,
                  }"
                    >
                    12
                  </div>
              </div>
            </template>
            <div class="space-y-2">
              <template
                v-for="(item, index) in 3"
                :key="index"
              >
                <ListItem
                  class="text-sm flex items-center gap-[14px] group select-none"
                >
                 <img class="h-6 w-6 rounded-full object-cover" src="https://i.pravatar.cc/101" alt="Profile 1" />

                  <div
                    v-tooltip="item"
                    class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                  >
                    {{ 'item title' }}
                  </div>

                </ListItem>
              </template>
            </div>
          </Collapsible>
          <hr class="border-[#E0E0E0] my-3">
        </div>
      </template>
    </CstDrawer>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import CstDrawer from '@ui/Drawer/CstDrawer.vue'
import { ListItem, Button, Collapsible } from '@contentstudio/ui'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const { getSocialImageRounded } = useComposerHelper()
const {
  inboxEditProfile,
  inboxLocation,
  inboxJob,
  inboxInfo,
  inboxEmail,
  inboxCompany,
  inboxPhone,
 } = useIcons()

const {
  currentConversation,
} = useInbox()

const detailsSection = [
  {title: 'Email', icon: inboxEmail, key: 'email'},
  {title: 'Description', icon: inboxInfo, key: 'description'},
  {title: 'Location', icon: inboxLocation, key: 'location'},
  {title: 'Phone', icon: inboxPhone, key: 'phone'},
  {title: 'Job title', icon: inboxJob, key: 'job_title'},
  {title: 'Company', icon: inboxCompany, key: 'company'},
]

const userInfo = ref({
  email: '',
  description: '',
  location: '',
  phone: '',
  job_title: '',
  company: '',
})

const showFilterDrawer = ref(false)

const postBy = computed(() => {
  return currentConversation.value?.inbox_details?.posted_by
})
const toggleFilterDrawer = () => {
  showFilterDrawer.value = !showFilterDrawer.value
  console.log(showFilterDrawer.value)
}

</script>
