<template>
  <div>
    <Button
      v-tooltip="'Details'"
      variant="text"
      color="secondary"
      size="sm"
      radius="lg"
      class="!font-normal !cstu-text-gray-500 cstu-border cstu-border-gray-400/20 hover:cstu-bg-gray-100/50 hover:cstu-border-gray-400/25"
      @click="toggleFilterDrawer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5 8.005C12.8807 8.005 14 6.88569 14 5.505C14 4.12429 12.8807 3.005 11.5 3.005C10.3351 3.005 9.35631 3.80174 9.07875 4.88H2.125C1.77983 4.88 1.5 5.15983 1.5 5.505C1.5 5.85019 1.77983 6.13 2.125 6.13H9.07875C9.35631 7.20825 10.3351 8.005 11.5 8.005ZM10.25 5.505C10.25 6.19536 10.8096 6.755 11.5 6.755C12.1904 6.755 12.75 6.19536 12.75 5.505C12.75 4.81465 12.1904 4.255 11.5 4.255C10.8096 4.255 10.25 4.81465 10.25 5.505ZM1.5 10.505C1.5 9.12432 2.61929 8.005 4 8.005C5.1649 8.005 6.14371 8.80175 6.42124 9.88H13.375C13.7202 9.88 14 10.1598 14 10.505C14 10.8502 13.7202 11.13 13.375 11.13H6.42124C6.14371 12.2083 5.1649 13.005 4 13.005C2.61929 13.005 1.5 11.8857 1.5 10.505ZM5.25 10.505C5.25 9.81463 4.69036 9.255 4 9.255C3.30964 9.255 2.75 9.81463 2.75 10.505C2.75 11.1954 3.30964 11.755 4 11.755C4.69036 11.755 5.25 11.1954 5.25 10.505Z"
          fill="#4A4A4A"
        />
      </svg>
      <span class="hidden xl:inline-block">
        Filter
      </span>
    </Button>
    <!-- Filter Drawer -->
    <CstDrawer
      :model-value="showFilterDrawer"
      title="Filter"
      position="left"
      body-class="!overflow-visible"
      :width="350"
      :no-close-on-backdrop="false"
      @update:model-value="showFilterDrawer = $event"
    >
      <template v-slot:header="{ close }">
        <div class="h-[4.25rem] bottom-border-only flex items-center justify-between py-[16px] px-[14px]">
          <div class="flex items-center gap-[6px]" >
            <Button
                variant="text"
                color="secondary"
                size="sm"
                class="hover:cstu-text-red-500 !font-normal"
                @click="clearFilters"
              >
                Clear Filters
            </Button>
            <span class="h-[15px] w-[1px] bg-gray-500"></span>
            <SyncButton
                v-tooltip="'Sync your data across selected social accounts'"
                :loading="isSyncing"
                @click="handleSync"
              />
          </div>
          <div class="cursor-pointer" @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
              <path d="M10.5605 9.5L13.7803 6.28027C14.0732 5.9873 14.0732 5.5127 13.7803 5.21973C13.4873 4.92676 13.0127 4.92676 12.7197 5.21973L9.5 8.43945L6.28027 5.21973C5.9873 4.92676 5.5127 4.92676 5.21973 5.21973C4.92676 5.5127 4.92676 5.9873 5.21973 6.28027L8.43945 9.5L5.21973 12.7197C4.92676 13.0127 4.92676 13.4873 5.21973 13.7803C5.36621 13.9268 5.55811 14 5.75 14C5.94189 14 6.13379 13.9268 6.28027 13.7803L9.5 10.5605L12.7197 13.7803C12.8662 13.9268 13.0581 14 13.25 14C13.4419 14 13.6338 13.9268 13.7803 13.7803C14.0732 13.4873 14.0732 13.0127 13.7803 12.7197L10.5605 9.5Z" fill="#4A4A4A"/>
            </svg>
          </div>
        </div>
      </template>
      <template v-slot>
        <div class="px-[13.5px] py-[11px] h-full">
          <div class="flex flex-col gap-[6px]">
            <template v-for="(item, index) in filterOptions" :key="index">
              <ListItem
                :selected="item.value === selectedFilterOption.value"
                class="flex items-center gap-[14px] group select-none h-[30px] hover:font-medium"
                :class="{
                  '!font-medium': item.value === selectedFilterOption.value,
                }"
                @click="selectedFilterOption = item"
              >
                <img
                  :src="item.icon"
                  alt="list icon"
                  class="grayscale group-hover:grayscale-0"
                  :class="{
                    '!grayscale-0': item.value === selectedFilterOption.value,
                  }"
                />
                <p class="flex-1 text-sm leading-none ">
                  {{ item.label }}
                </p>
                <template v-if="selectedFilterOption.value === item.value">
                  <img
                  :src="inboxTickMark"
                  alt="selected list icon"
                />
                </template>
              </ListItem>
            </template>
          </div>
          <hr class="border-[#E0E0E0] my-3">
          <div class="flex flex-col gap-[12px] h-[calc(100%-4.25rem)]">
            <!-- Social Platform Filters -->
            <template v-if="selectedFilterOption.value === 'social'">
              <!-- select by platform  -->
              <div class="flex items-center justify-between px-3">
                <div class="flex items-center gap-3 ">
                  <span class="text-[#75797C] text-sm font-normal">{{ selectedPlatformFilter.filterTitle }}</span>
                  <Dropdown placement="bottom" dropdown-classes="!min-w-[200px] !z-[1024]" button-classes="border-none hover:!border-none !px-0 !gap-0">
                    <template v-slot:selected>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g clip-path="url(#clip0_34264_15073)">
                            <path d="M5.61639 2.57959H1.52555C1.14899 2.57959 0.84375 2.88483 0.84375 3.26139V7.35223C0.84375 7.72879 1.14899 8.03404 1.52555 8.03404H5.61639C5.99295 8.03404 6.2982 7.72879 6.2982 7.35223V3.26139C6.2982 2.88483 5.99295 2.57959 5.61639 2.57959ZM4.93459 6.67043H2.20735V3.94319H4.93459V6.67043Z" fill="#4A4A4A"/>
                            <path d="M15.162 4.625H8.34391C7.96735 4.625 7.66211 4.93024 7.66211 5.3068C7.66211 5.68336 7.96735 5.9886 8.34391 5.9886H15.162C15.5386 5.9886 15.8438 5.68336 15.8438 5.3068C15.8438 4.93027 15.5385 4.625 15.162 4.625Z" fill="#4A4A4A"/>
                            <path d="M15.162 11.4431H8.34391C7.96735 11.4431 7.66211 11.7484 7.66211 12.1249C7.66211 12.5014 7.96735 12.8067 8.34391 12.8067H15.162C15.5386 12.8067 15.8438 12.5014 15.8438 12.1249C15.8438 11.7484 15.5385 11.4431 15.162 11.4431Z" fill="#4A4A4A"/>
                            <path d="M5.13937 10.2765L2.86964 12.519L2.01602 11.6327C1.75413 11.3619 1.32235 11.3548 1.0516 11.6166C0.780853 11.8785 0.773677 12.3103 1.03557 12.5811L2.36781 13.9658C2.49485 14.0954 2.66835 14.169 2.84986 14.1703H2.85804C3.03679 14.1698 3.2082 14.099 3.3353 13.9733L6.0939 11.2461C6.3793 11.0005 6.41155 10.5699 6.16593 10.2845C5.92031 9.99915 5.4898 9.9669 5.2044 10.2125C5.18134 10.2324 5.1596 10.2538 5.13937 10.2765Z" fill="#4A4A4A"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_34264_15073">
                              <rect width="15" height="15" fill="white" transform="translate(0.84375 0.875)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </template>
                    <template v-slot:arrow>
                      <span></span>
                    </template>
                    <span class="text-[#75797C] text-sm font-normal">Group By</span>
                    <hr/>
                    <template v-for="option in platformFilterOptions" :key="option.value">
                      <DropdownItem
                        size="sm"
                        :selected="selectedPlatformFilter.value === option.value"
                        @click="selectedPlatformFilter = option"
                      >
                        <div class="flex items-center justify-between">
                          {{ option.label }}
                          <img v-if="selectedPlatformFilter.value === option.value" :src="inboxTickMark" alt="check icon">
                        </div>
                      </DropdownItem>
                    </template>
                  </Dropdown>
                </div>
                <Checkbox
                  :model-value="isAllSelected"
                  :indeterminate="isIndeterminate"
                  @update:model-value="value => value ? selectAllChannels() : deselectAllChannels()"
                />
              </div>
              <!-- Menu -->
              <div class="flex-1 flex flex-col gap-4 overflow-auto">
                <template v-if="selectedPlatformFilter.value === 'with'">
                  <template v-for="(channels, platform) in filteredChannels" :key="platform">
                    <Collapsible
                      :default-open="true"
                      :hide-arrow="true"
                      button-class="cstu-bg-gray-100 hover:cstu-bg-gray-100 active:cstu-bg-gray-100"
                      selected-class="cstu-bg-gray-100 hover:cstu-bg-gray-100 active:cstu-bg-gray-100"
                    >
                      <template v-slot:trigger="{ open }">
                        <div class="flex flex-1 items-center gap-[11px] select-none">
                          <div class="transition-all" :class="{ 'rotate-180': open }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                              <g clip-path="url(#clip0_34274_15213)">
                                <path d="M5.78516 8.80554L9.95312 4.63757C10.0534 4.53731 10.1035 4.41557 10.1035 4.27234C10.1035 4.12911 10.0534 4.00736 9.95312 3.9071L9.48047 3.43445C9.38021 3.33419 9.25846 3.28048 9.11523 3.27332C8.97201 3.26615 8.85026 3.3127 8.75 3.41296L5.41992 6.74304L2.08984 3.41296C1.98958 3.3127 1.86784 3.26615 1.72461 3.27332C1.58138 3.28048 1.45964 3.33419 1.35938 3.43445L0.886719 3.9071C0.786458 4.00736 0.736328 4.12911 0.736328 4.27234C0.736328 4.41557 0.786458 4.53731 0.886719 4.63757L5.05469 8.80554C5.15495 8.92013 5.27669 8.97742 5.41992 8.97742C5.56315 8.97742 5.6849 8.92013 5.78516 8.80554Z" fill="#75797C"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_34274_15213">
                                  <rect width="9.84" height="11" fill="white" transform="matrix(-1 0 0 -1 10.3398 11.62)"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div class="flex-1 truncate">
                            <span class="text-[#3A4557] font-medium truncate capitalize">{{ platform }}</span>
                          </div>

                          <div
                            v-tooltip="`${channels.length}`"
                            class="text-[#3A4557] text-xs leading-none font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#E8E8E8]"
                          >
                            {{ formatNumber(channels.length) }}
                          </div>
                          <div @click.stop>
                            <Checkbox
                              :model-value="isPlatformAllSelected(platform)"
                              :indeterminate="isPlatformIndeterminate(platform)"
                              @update:model-value="value => value ? selectPlatformChannels(platform) : deselectPlatformChannels(platform)"
                            />
                          </div>
                        </div>
                      </template>
                      <div class="space-y-1">
                        <template v-for="(channel) in channels" :key="channel.platform_id">
                          <ChannelListItem
                            :channel="channel"
                            :platform="platform"
                            :selected="selectedChannels[platform].includes(channel.platform_id)"
                            @click="toggleChannelSelection(platform, channel.platform_id)"
                          />
                        </template>
                      </div>
                    </Collapsible>
                  </template>
                </template>
                <template v-else>
                  <div class="space-y-1">
                    <template v-for="(channels, platform) in filteredChannels" :key="platform">
                      <template v-for="(channel) in channels" :key="channel.platform_id">
                        <ChannelListItem
                          :channel="channel"
                          :platform="platform"
                          :selected="selectedChannels[platform].includes(channel.platform_id)"
                          @click="toggleChannelSelection(platform, channel.platform_id)"
                        />
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </template>
            <!-- Tags Filter -->
            <template v-else>
              <div>
                <div class="flex items-center justify-between px-3">
                  <div class="flex items-center gap-3">
                    <span class="text-[#75797C] text-sm font-normal">Tags</span>
                  </div>
                  <Checkbox
                    v-if="tags?.length"
                    :model-value="isAllTagsSelected"
                    :indeterminate="isTagsIndeterminate"
                    @update:model-value="value => value ? selectAllTags() : deselectAllTags()"
                  />
                </div>
              </div>
              <div v-if="!tags?.length" class="px-3 py-4 text-center">
                <p class="text-sm cstu-text-gray-500">No tags available</p>
                <p class="text-xs cstu-text-gray-400 mt-1">Create tags to organize your conversations</p>
              </div>
              <div v-else class="flex-1 flex flex-col gap-[6px] overflow-auto">
                <template v-for="(item) in tags" :key="item?._id">
                  <ListItem
                    class="flex items-center gap-[14px] group select-none h-[30px] hover:font-medium"
                    :class="{
                      '!font-medium': isTagSelected(item._id),
                    }"
                    :selected="isTagSelected(item._id)"
                    @click="toggleTagSelection(item._id)"
                  >
                    <TagIcon :class="`text_${item?.tag_color}`" />
                    <p class="flex-1 text-sm truncate text-[#3A4557]">
                      {{ item.tag_name }}
                    </p>
                    <Checkbox
                      :model-value="isTagSelected(item._id)"
                    />
                  </ListItem>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </CstDrawer>
  </div>
</template>

<script setup>
import { ref,onMounted } from 'vue'
import CstDrawer from '@ui/Drawer/CstDrawer.vue'
import { ListItem, Collapsible, Button, Checkbox, Dropdown, DropdownItem } from '@contentstudio/ui'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import ChannelListItem from '@src/modules/inbox-revamp/components/ChannelListItem.vue'
import TagIcon from '@src/modules/inbox-revamp/components/TagIcon.vue'
import SyncButton from '@src/modules/inbox-revamp/components/SyncButton.vue'

const { inboxTickMark, inboxShareIcon, inboxTagOrangeIcon } = useIcons()
const {
  filteredChannels,
  selectedChannels,
  tags,
  selectedTags,
  isAllSelected,
  isIndeterminate,
  isAllTagsSelected,
  isTagsIndeterminate,
  isTagSelected,
  toggleTagSelection,
  selectAllTags,
  deselectAllTags,
  fetchTags,
  toggleChannelSelection,
  selectAllChannels,
  deselectAllChannels,
  selectPlatformChannels,
  deselectPlatformChannels,
  isPlatformAllSelected,
  isPlatformIndeterminate,
  triggerPlatformSyncJob,
} = useInbox()

console.log('filteredChannels',filteredChannels.value)

const filterOptions = ref([
  { label: 'Social Platforms', value: 'social', icon: inboxShareIcon  },
  { label: 'Tags', value: 'tags', icon: inboxTagOrangeIcon },
])

const platformFilterOptions = [
  { value: 'with', label: 'Platforms', filterTitle: 'By Platform' },
  { value: 'without', label: 'Without Platforms', filterTitle: 'All Platforms' }
]

const showFilterDrawer = ref(false)
const selectedFilterOption = ref(filterOptions.value[0])
const selectedPlatformFilter = ref(platformFilterOptions[0])
const isSyncing = ref(false)

onMounted(() => {
  fetchTags()
})

const toggleFilterDrawer = () => {
  showFilterDrawer.value = !showFilterDrawer.value
  console.log(showFilterDrawer.value)
}

const clearFilters = () => {
  selectedChannels.value = {}
  selectAllChannels()
  selectedTags.value = []
}

const handleSync = async() => {
  isSyncing.value = true
  await triggerPlatformSyncJob(true)
  isSyncing.value = false
}
</script>
