<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    content-class="!w-11/12"
    dialog-class="cst-modal !justify-center"
  >
    <template v-slot:modal-header="{ close }">
      <div class="flex items-center justify-between w-full px-4 py-2">
        <h3 class="text-lg font-medium">Top {{ posts }} for {{ date }}</h3>
        <div
          v-tooltip="{
            content: 'Close',
            theme: 'light',
          }"
          class="cursor-pointer w-[1vw] h-[1vh] flex justify-center items-center rounded-full"
          @click="handleClose(close)"
        >
          <i class="fa fa-times fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </template>

    <div class="p-4">
      <AnalyticsPostsTable
        :data-list="topPostsData"
        :valid-headers-list="validHeadersList"
        :non-sortable-items="nonSortableItems"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeaderTooltips"
        :is-loading="topPostsData.length === 0"
        custom-no-data-message="No posts found for this date"
      >
        <template v-slot:post-modal="{ selectedPost }">
          <InstagramPostModal
            v-if="selectedPost"
            :selected-post="selectedPost"
          />
        </template>
      </AnalyticsPostsTable>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { EventBus } from "@common/lib/event-bus"
import AnalyticsPostsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import InstagramPostModal from './InstagramPostModal.vue'

const {
  validPostsTableHeaders: validHeadersList,
  nonSortableItems,
  getHeaderTitles,
  getBodyValues,
  getHeaderTooltips,
  topPostsData
} = useInstagramAnalytics()

const showModal = ref(false)
const date = ref('')
const posts = ref('posts')

const handleShowModal = ({ date: selectedDate, posts: postsData }) => {
  date.value = selectedDate
  showModal.value = true
  posts.value = postsData
  console.log('showModal', showModal.value)
}

onMounted(() => {
  EventBus.$on('show-top-posts-modal', handleShowModal)
})

onBeforeUnmount(() => {
  EventBus.$off('show-top-posts-modal', handleShowModal)
})

const handleClose = (close) => {
  topPostsData.value = [] // Reset posts to an empty array
  close() // Call the original close function to close the modal
}
</script>
