<template>
  <div
    class="transition-all duration-300 relative flex overflow-hidden"
    :style="mainDivStyle"
  >
    <!-- Sidebar Container -->
    <div class="relative z-50">
      <CstSidebar
        ref="sidebarRef"
        v-model="isMobileOpen"
        v-model:expanded="isExpanded"
      >
        <!-- Expanded Header -->
        <template v-slot:head-expanded>
          <div
            class="flex items-center justify-between h-[4.25rem] bottom-border-only"
          >
            <div class="flex items-center px-6">
              <FilterDrawer />
              <SyncButton
                v-tooltip="'Sync your data across all of your social accounts'"
                :loading="isSyncing"
                @click="handleSync"
              />
            </div>
            <SidebarToggleIconButton
              v-tooltip="'Collapse'"
              @click="sidebarRef?.toggleExpand()"
            />
          </div>
        </template>

        <!-- Shrunk Header -->
        <template v-slot:head-shrink>
          <div
            class="flex items-center justify-center h-[4.25rem] bottom-border-only"
          >
            <div class="rotate-180">
              <SidebarToggleIconButton
                v-tooltip="'Expand'"
                @click="sidebarRef?.toggleExpand()"
              />
            </div>
          </div>
        </template>

        <!-- Expanded Body -->
        <template v-slot:body-expanded>
          <div class="px-3 pt-3 space-y-2">
            <template v-for="(item, index) in sidebarItems" :key="index">
              <ListItem
                :selected="currentViewRoute === item.route"
                class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                @click="router.push({ name: 'inbox-revamp', params: { filter: item.route } })"
              >
                <img
                  :src="item.icon"
                  alt="icon"
                  class="grayscale group-hover:grayscale-0"
                  :class="{
                    '!grayscale-0': currentViewRoute === item.route,
                  }"
                />
                <div
                  v-tooltip="item.title"
                  class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                  :class="{
                    '!font-medium': currentViewRoute === item.route,
                  }"
                >
                  {{ item.title }}
                </div>

                <div
                  v-tooltip="`${conversationCount[item.countKey] || 0}`"
                  class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                  :class="{
                    '!bg-[#D8EFFF] !font-medium': currentViewRoute === item.route,
                  }"
                >
                  {{ formatNumber(conversationCount[item.countKey] || 0) }}
                </div>

              </ListItem>
            </template>
            <hr />
            <Collapsible :default-open="true" :button-class="'!py-[8px]'">
              <template v-slot:trigger>
                <span class="text-[#3A4557] font-normal"> Others </span>
              </template>
              <div class="space-y-2">
                <template
                  v-for="(item, index) in otherSidebarItems"
                  :key="index"
                >
                  <ListItem
                    :selected="currentViewRoute === item.route"
                    class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                    @click="router.push({ name: 'inbox-revamp', params: { filter: item.route } })"
                  >
                    <img
                      :src="item.icon"
                      alt="icon"
                      class="grayscale group-hover:grayscale-0"
                      :class="{
                        '!grayscale-0': currentViewRoute === item.route,
                      }"
                    />
                    <div
                      v-tooltip="item.title"
                      class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                      :class="{
                        '!font-medium': currentViewRoute === item.route,
                      }"
                    >
                      {{ item.title }}
                    </div>
                    <div
                      v-if="item?.countKey"
                      v-tooltip="`${conversationCount[item.countKey] || 0}`"
                      class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                      :class="{
                        '!bg-[#D8EFFF] !font-medium': currentViewRoute === item.route,
                      }"
                    >
                      {{ formatNumber(conversationCount[item.countKey] || 0) }}
                    </div>
                  </ListItem>
                </template>
              </div>
            </Collapsible>
            <hr />
          </div>
        </template>

        <!-- Shrunk Body -->
        <template v-slot:body-shrink>
          <div class="px-3 pt-3 space-y-2">
            <template
              v-for="(item, index) in [...sidebarItems, ...otherSidebarItems]"
              :key="index"
            >
              <ListItem
                v-tooltip.right="item.title"
                :selected="currentViewRoute === item.route"
                class="text-sm flex items-center gap-[14px] group select-none"
                @click="router.push({ name: 'inbox-revamp', params: { filter: item.route } })"
              >
                <img
                  :src="item.icon"
                  :alt="item.title"
                  class="w-5 h-5 grayscale group-hover:grayscale-0"
                  :class="{
                    '!grayscale-0': currentViewRoute === item.route,
                  }"
                />
              </ListItem>
            </template>
          </div>
        </template>
      </CstSidebar>
    </div>

    <!-- Message List Container with Fixed Width -->
    <div
      class="transition-all duration-300 relative"
      :class="[
        'w-[30rem]',
        {
          'opacity-50': isMobileOpen && isMobileView,
        },
      ]"
    >
      <InboxListing />
    </div>

    <!-- Main Content -->
    <div
      class="flex-1 bg-gray-100 transition-all duration-300 overflow-auto"
      :class="{
        'opacity-50': isMobileOpen && isMobileView,
      }"
    >
    <template v-if="conversationList?.conversations?.length > 0">
      <router-view></router-view>
    </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted , watch } from 'vue'
import CstSidebar from '@ui/Sidebar/CstSidebar.vue'
import usePlatform from '@common/composables/usePlatform'
import SidebarToggleIconButton from '@src/modules/inbox-revamp/components/SidebarToggleIconButton.vue'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import useNumber from '@src/modules/common/composables/useNumber'
import { ListItem, Collapsible } from '@contentstudio/ui'
import InboxListing from '@src/modules/inbox-revamp/components/InboxListing.vue'
import FilterDrawer from '@src/modules/inbox-revamp/components/FilterDrawer.vue'
import { useRouter } from 'vue-router'
import { commonMethods } from '@common/store/common-methods'
import SyncButton from '@src/modules/inbox-revamp/components/SyncButton.vue'
import { EventBus } from '@common/lib/event-bus'
import { debounce } from 'lodash'

const router = useRouter()
const { formatNumber } = useNumber()
const {
  sidebarItems,
  otherSidebarItems,
  conversationCount,
  selectedChannels,
  inboxTypes,
  selectedInboxType,
  conversationList,
  selectedTags,
  selectedAssingedTo,
  selectedAssingedBy,
  selectedMarkedDoneBy,
  selectedArchivedBy,
  fetchDetails,
  fetchConversationList,
  triggerPlatformSyncJob,
  initializeSelectedChannels
} = useInbox()

const { topBannersHeight } = usePlatform()

const sidebarRef = ref(null)
const isMobileOpen = ref(false)
const isExpanded = ref(true)
const isMobileView = ref(false)
const isSyncing = ref(false)

// Set up resize listener
onMounted(() => {
  commonMethods.toggleWidgets(true)
  checkMobileView()
  window.addEventListener('resize', checkMobileView)

  initializeSelectedChannels()
  fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
  fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)

  EventBus.$on('fetched-social-accounts', async () => {
    console.log('fetched-social-accounts')
    initializeSelectedChannels()
    fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
    fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  })
  // fetchConversationCounts()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkMobileView)
  EventBus.$off('fetched-social-accounts')
  commonMethods.toggleWidgets()
})

const currentViewRoute = computed(() => {
  return router?.currentRoute?.value?.params?.filter || 'all'
})

watch(
  () => [selectedChannels.value, selectedTags.value],
  debounce(async () => {
    fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
    fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  }, 1000),
  { deep: true }
)

watch(
  () => currentViewRoute.value,
  debounce(async (value, oldValue) => {
    console.log('currentViewRoute.value', value, oldValue)
    if (value === oldValue) {
      return
    }
    // resetting the actions filter:
    selectedAssingedTo.value = []
    selectedAssingedBy.value = []
    selectedMarkedDoneBy.value = []
    selectedArchivedBy.value = []
    fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
    fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  }, 500),
)

const mainDivStyle = computed(() => {
  return {
    height: `calc(100vh - ${topBannersHeight.value}px)`,
  }
})

// Check if mobile view
const checkMobileView = () => {
  isMobileView.value = window.innerWidth <= 1024 // lg breakpoint
}

const handleSync = async() => {
  isSyncing.value = true
  // This triggers all of the platforms
  await triggerPlatformSyncJob()
  isSyncing.value = false
}

</script>

<style>
.bottom-border-only {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-x-only {
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}
</style>
