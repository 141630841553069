<template>
  <div
      class="px-4 py-2"
    >
      <div class="mb-3 relative">
        <input
          v-model="campaignName"
          type="text"
          :placeholder="placeholder"
          class="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm"
        @click.stop
      />
      <div class="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 ">
        <slot name="prefix-icon" />
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex gap-2 relative items-center">
        <p class="text-sm font-medium text-gray-900">Color</p>
        <img
          v-click-away="hideColorPicker"
          src="@src/assets/img/common/color-picker.svg"
          class="h-4 w-4 cursor-pointer rounded-md p-0.5 border border-gray-400"
          :style="{
            backgroundColor: hexToRGBA(
              LABELS_COLOR_MAP[selectedColor],
              0.8,
            ),
          }"
          alt="color picker"
          @click.stop="showColorPicker = !showColorPicker"
        />
        <div
          v-if="showColorPicker"
          class="absolute left-0 top-full bg-white rounded-xl w-52 p-1 grid grid-cols-10 shadow-sm gap-1 border border-gray-200 z-100"
          @click.stop
        >
          <div
            v-for="(color, key) in LABELS_COLOR_MAP"
            :key="color"
            :class="`w-4 h-4 rounded-md border border-gray-200 relative cursor-pointer`"
            :style="`background-color: ${hexToRGBA(color, 0.8)}`"
            @click="selectColor(key)"
          >
            <img
              v-if="key === selectedColor"
              src="@src/assets/img/chat_bot/tick.svg"
              alt="selected"
              class="absolute inset-0 m-auto w-2 h-2"
            />
          </div>
        </div>
      </div>

      <div class="flex items-center gap-2 mr-2">
        <Button
          variant="text"
          color="secondary"
          size="xs"
          @click.stop="cancel"
        >
          Cancel
        </Button>
        <Button
          :disabled="!campaignName || !selectedColor || isLoading"
          size="xs"
          @click.stop="create"
        >
          Create
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Button } from '@contentstudio/ui'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['onCreate', 'onCancel'])

const LABELS_COLOR_MAP = {
  color_1: '#69c366',
  color_2: '#5cc6ff',
  color_3: '#ff6462',
  color_4: '#fea28b',
  color_5: '#ff5f31',
  color_6: '#864de9',
  color_7: '#e7af4d',
  color_8: '#fa6ab6',
  color_9: '#0095f3',
  color_10: '#dc70ea',
  color_11: '#456990',
  color_12: '#028090',
  color_13: '#ffa13f',
  color_14: '#231942',
  color_15: '#544c72',
  color_16: '#975816',
  color_17: '#0e4749',
  color_18: '#a5be00',
  color_19: '#fc1100',
  color_20: '#000000',
}

const { hexToRGBA } = useComposerHelper()

const campaignName = ref('')
const showColorPicker = ref(false)
const selectedColor = ref('color_1')

const selectColor = (key) => {
  selectedColor.value = key
  showColorPicker.value = false
}

// Hide Color Picker
const hideColorPicker = () => {
  showColorPicker.value = false
}

const create = () => {
  emit('onCreate', { name: campaignName.value, color: selectedColor.value })
  resetStates()
}

const cancel = () => {
  emit('onCancel')
  resetStates()
}

const resetStates = () => {
  campaignName.value = ''
  showColorPicker.value = false
  selectedColor.value = 'color_1'
}
</script>
