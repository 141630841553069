<template>
  <div class="comment-block relative">
    <!-- Vertical line for parent comments -->
    <div
      v-if="hasReplies || showReplyBox"
      class="absolute bottom-[80px] left-[35px] top-[59px] hidden w-[2px] bg-gray-200 sm:block"
    ></div>

    <!-- Corner elbow for replies -->
    <div
      v-if="isReply"
      class="absolute top-[-10px] left-[-17px] h-12 w-7 rounded-bl-3xl border-2 border-r-0 border-t-0 border-gray-200"
    ></div>

    <!-- Comment Card -->
    <div class="rounded bg-white p-4">
      <div class="flex items-start space-x-3">
        <img
          :src="comment?.from?.[0]?.profile_pic || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
          :alt="userName"
          class="h-10 w-10 rounded-full object-cover"
          @error="$event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
        />
        <div class="flex-1">
          <div class="rounded-3xl bg-gray-100 p-3">
            <!-- Name & Time -->
            <div class="flex items-center justify-between">
              <h4 class="font-semibold text-sm">{{ userName }}</h4>
            </div>

            <!-- Comment Text -->
            <HtmlRenderer
              v-if="comment.message?.length > 0"
              tag="p"
              class="mt-1 text-sm"
              :html-content="parseDescriptionHtml(trimmedMessage, trimmedMessage?.length, true, post?.platform === 'linkedin')"
            />
            <template v-if="isContentLarge">
              <span class="cursor-pointer hover:underline text-cs-primary" @click="toggleReadMore">{{ !readMore ? 'Read more' : 'Read less' }}</span>
            </template>
          </div>

          <div class="mt-2 flex justify-between">
            <div class="flex items-center space-x-4 text-sm">
              <span class="text-xs">{{ formatTime(comment.created_time) }}</span>
              <button class="hover:underline" @click="$emit('like', comment)">Like</button>
              <button class="hover:underline" @click="$emit('reply', comment)">Reply</button>
              <button v-if="canDelete" class="hover:underline" @click="$emit('delete', comment)">Delete</button>
              <button class="hover:underline" @click="$emit('hide', comment)">Hide</button>
            </div>
            <div class="flex items-center justify-between gap-3 text-sm">
              <span v-if="comment.replies_count">{{ comment.replies_count }} Replies</span>
              <span v-if="comment.likes_count">{{ comment.likes_count }} ♥</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Replies Container -->
     <template v-if="showReplyBox">
       <div  class="ml-10 mt-2 space-y-2 sm:ml-14">
         <template v-if="hasReplies">
           <CommentBlock
             v-for="reply in comment.children"
             :key="reply._id"
             :comment="reply"
             :is-reply="true"
             :can-delete="canDelete"
             @like="$emit('like', $event)"
             @reply="$emit('reply', $event)"
             @delete="$emit('delete', $event)"
             @hide="$emit('hide', $event)"
           />
         </template>
         <div class="relative">
           <div
           class="absolute top-[-10px] left-[-17px] h-12 w-7 rounded-bl-3xl border-2 border-r-0 border-t-0 border-gray-200"
         ></div>
           <div class="rounded bg-white p-4">
             <div class="flex items-start space-x-3">
               <img
                 :src="comment?.from?.[0]?.profile_pic || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                 :alt="userName"
                 class="h-10 w-10 rounded-full object-cover"
                 @error="$event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
               />
               <div class="flex-1">
                 <MessageComposer
                   :active-inbox-detail="activeInboxDetail"
                   :allow-note="false"
                   :mini-box="true"
                   :skip-modal-render="true"
                 />
               </div>
             </div>
           </div>
         </div>
       </div>
     </template>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { parseDescriptionHtml } from '@common/lib/helper'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import MessageComposer from '@src/modules/inbox-revamp/components/MessageComposer.vue'
import useDateFormat from '@/src/modules/common/composables/useDateFormat'

const { momentWrapper } = useDateFormat()

const props = defineProps({
  comment: {
    type: Object,
    required: true
  },
  isReply: {
    type: Boolean,
    default: false
  },
  canDelete: {
    type: Boolean,
    default: false
  },
  activeInboxDetail: {
    type: Object,
    required: true
  }
})

defineEmits(['like', 'reply', 'delete', 'hide'])

const readMore = ref(false)
const showReplyBox = ref(true)

const hasReplies = computed(() => {
  return props.comment.children?.length > 0
})

const isContentLarge = computed(() => {
  return props.comment.message?.length > 200
})

const trimmedMessage = computed(() => {
  if(readMore.value || !isContentLarge.value) return props.comment.message
  return props.comment.message?.substr(0, 200) + '...'
})

const userName = computed(() => {
  return `${props.comment?.from[0]?.first_name} ${props.comment?.from[0]?.last_name}`
})

const toggleReadMore = () => {
  readMore.value = !readMore.value
}

const formatTime = (time) => {
  return momentWrapper(time).formatTimezone().fromNow(true)
}
</script>
