import io from 'socket.io-client'

import { apiUrl, inboxApiUrl } from '@src/config/api-utils'

export const WORKSPACE_ID = 'workspace_id'
export const DEFAULT_IMAGE =
  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

// MARK:- service call urls
export const LUMOTIVE_INBOX = inboxApiUrl + 'inbox'
export const LUMOTIVE_INBOX_SAVED_REPLIES = apiUrl + 'inbox/saved/reply'

export const socket = io(process.env.VUE_APP_SOCKET_NOTIFICATIONS_BASE_URL, {
  transports: ['websocket'],
})

export const INBOX_ACTION_TITLES = [
  'Permission denied!',
  'Action failed!',
  'Resource not found!',
  'Resource found!',
  'Action successful!',
]
