<template>
  <div class="flex flex-col flex-1 items-center gap-4 bg-[#f4f7fa] py-8 px-6 overflow-hidden">
    <div class=" flex flex-col w-full items-center overflow-y-auto">
      <div class="post-view max-w-[650px] w-full">
        <!-- Post Header -->
        <div class="flex items-center gap-10 px-4 py-3">
          <div class="flex flex-1 items-center gap-2">
            <img
              :src="userDetails?.image"
              alt=""
              class="w-[40px] h-[40px] rounded-full"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <div>
              <p class="font-medium text-sm">{{ userDetails?.name }}</p>
              <p class="text-xs">{{ momentWrapper(post?.created_at).formatDateTime() }}</p>
            </div>
          </div>
          <div>
            <a
              v-tooltip="{
                content: tooltipHtml(`View post on ${post?.platform}`),
                allowHTML: true,
                theme: 'light',
              }"
              :href="sanitizeUrl(postDetails?.link)"
              target="_blank" rel="noopener"
            >
              <img :src="externalLinkIcon" alt="External Link Icon" />
            </a>
          </div>
        </div>

        <!-- Post Content -->
        <div class="flex flex-col gap-4">
          <!-- Text Content -->
           <template v-if="inboxType === 'review'">
            <div class="flex items-center px-4">
              <span
                v-for="star in 5"
                :key="star"
                class="flex items-center"
              >
                <svg
                  v-if="star <= getStarLimit(postDetails?.rating)"
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Rating {{ star }}</title>
                  >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>
                <svg
                  v-else
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-300 dark:text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Rating {{ star }}</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>
              </span>
            </div>
          </template>
          <div>
          <HtmlRenderer
            v-if="postDetails?.post_message?.length > 0"
            tag="div"
            class="text-sm whitespace-pre-wrap px-4"
            :class="{ 'order-last': post?.platform === 'instagram' }"
            :html-content="parseDescriptionHtml(trimmedPostMessage, trimmedPostMessage?.length, true, post?.platform === 'linkedin')"
          />
          <template v-if="isPostContentLarge">
            <div class="px-4">
              <span class="cursor-pointer hover:underline text-cs-primary" @click="togglePostReadMore">
                {{ !postReadMore ? 'Read more' : 'Read less' }}
              </span>
            </div>
          </template>
        </div>
          <!-- Media Content -->
          <template v-if="postDetails?.post_attachment?.length > 0">
            <template v-if="['images', 'photo'].includes(postAttachments?.is_type) || ['images', 'photo'].includes(postAttachments?.type)">
              <img
                :src="postAttachments?.post_image || postAttachments?.url || postAttachments?.image"
                alt=""

                class="rounded-lg w-full object-cover max-h-[400px]"
                @error="
                  (event) => {
                    event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
                  }
                "
              >
            </template>
            <template v-else-if="postAttachments?.is_type === 'video' || postAttachments?.type === 'videos' || postAttachments?.type === 'video_inline'">
              <video
                :src="postAttachments?.post_video"
                controls
                class="rounded-lg w-full max-h-[400px]"
              ></video>
            </template>
            <template v-else-if="postAttachments?.is_type === 'image_album'">
              <Carousel
                id="preview-carousel"
                class="facebook-carousel-preview"
                :navigation-next-label="navigationNext()"
                :navigation-prev-label="navigationPrev()"
                :navigation-enabled="true"
                :pagination-enabled="false"
                :scroll-per-page="false"
                :space-padding-max-offset-factor="20"
                :per-page="1"
            >
              <template
                v-for="(img, index) in postAttachments?.post_image_album"
                :key="`carousel-index-${index}`"
              >
                <Slide class="">
                  <img
                      v-tooltip="{
                    content: 'Carousel Preview',
                  }"
                      class="rounded-lg w-full object-cover max-h-[400px]"
                      :class="getSlideClasses"
                      :src="img"
                      alt=""
                      @click="() => openImageLightBox(img, index)"
                      @error="
                    (event) => {
                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                    }
                  "
                  />
                </Slide>
              </template>
            </Carousel>
            </template>
            <template v-else-if="postAttachments?.is_type === 'carousel_album'">
              <Carousel
                id="preview-carousel"
                class="facebook-carousel-preview"
                :navigation-next-label="navigationNext()"
                :navigation-prev-label="navigationPrev()"
                :navigation-enabled="true"
                :pagination-enabled="false"
                :scroll-per-page="false"
                :space-padding-max-offset-factor="20"
                :per-page="1"
            >
              <template
                v-for="(item, index) in postAttachments?.post_image_album"
                :key="`carousel-index-${index}`"
              >
                <Slide class="">
                  <template v-if="item?.is_type === 'photo'">
                    <img
                        v-tooltip="{
                      content: 'Carousel Preview',
                    }"
                        class="rounded-lg w-full object-cover max-h-[400px]"
                        :class="getSlideClasses"
                        :src="item?.post_image"
                        alt=""
                        @click="() => openImageLightBox(item?.post_image, index)"
                        @error="
                      (event) => {
                        event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      }
                    "
                    />
                  </template>
                  <template v-else-if="item?.is_type === 'video'">
                    <video
                        :src="item?.post_video"
                        controls
                        class="rounded-lg w-full max-h-[400px]"
                    ></video>
                  </template>
                </Slide>
              </template>
            </Carousel>
            </template>
          </template>
        </div>
        <div>
          <hr v-if="inboxType !== 'review'" class="my-0"/>
          <!-- Post Actions -->
          <div v-if="inboxType !== 'review'" class="flex items-center justify-between px-4 py-3">
            <!-- stats -->
            <div class="flex items-center gap-2">
              <div class="flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                  <path d="M7.44527 13.5C6.99832 13.5 6.55138 13.3299 6.21115 12.9896L1.29826 8.07674C-0.432755 6.34572 -0.432755 3.52918 1.29826 1.79819C2.98606 0.110387 5.70601 0.0683896 7.44532 1.67193C9.18475 0.0696112 11.9035 0.112191 13.5909 1.79955C14.4294 2.63809 14.8912 3.75298 14.8912 4.93882C14.8912 6.12462 14.4293 7.23957 13.5909 8.07808L8.67941 12.9896C8.33915 13.3299 7.89221 13.5 7.44527 13.5ZM4.43755 1.6614C3.56249 1.6614 2.73981 2.00216 2.12103 2.62093C1.50226 3.2397 1.16148 4.06241 1.16148 4.93748C1.16148 5.81254 1.50223 6.63522 2.12101 7.254L7.03389 12.1669C7.26072 12.3937 7.62981 12.3937 7.85664 12.1669L12.7682 7.25536C13.3869 6.63659 13.7277 5.81391 13.7277 4.93884C13.7277 4.06378 13.387 3.2411 12.7682 2.62232C11.4908 1.34499 9.41248 1.34499 8.13512 2.62232L7.85664 2.90081C7.62943 3.12805 7.26107 3.12802 7.03387 2.90081L6.75404 2.62099C6.1353 2.00219 5.31262 1.6614 4.43755 1.6614Z" fill="#1E1E1E" fill-opacity="0.6"/>
                </svg>
                <span>{{ postDetails?.likes_count || 0 }}</span>
              </div>
              <div class="flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clip-path="url(#clip0_35153_8182)">
                    <path d="M8.27341 14.4454C8.17652 14.4454 8.07943 14.4214 7.99146 14.3731C7.80401 14.2702 7.6875 14.0733 7.6875 13.8595V12.6877C7.6875 12.3646 7.42465 12.1017 7.10156 12.1017H6.16406C3.25629 12.1017 0.890625 9.73605 0.890625 6.82828C0.890625 3.9205 3.25629 1.55484 6.16406 1.55484H10.6172C13.525 1.55484 15.8906 3.9205 15.8906 6.82828C15.8906 6.85251 15.8905 6.87662 15.8902 6.90067C15.8764 8.32107 15.4449 9.3856 14.4912 10.3524C13.6185 11.2371 12.3433 12.0236 10.5783 13.1122C9.98394 13.4788 9.31028 13.8943 8.58794 14.3538C8.49223 14.4147 8.38292 14.4454 8.27341 14.4454ZM6.16406 2.72672C3.90246 2.72672 2.0625 4.56668 2.0625 6.82828C2.0625 9.08988 3.90246 10.9298 6.16406 10.9298H7.10156C8.07082 10.9298 8.85938 11.7184 8.85938 12.6877V12.7982C9.25008 12.5546 9.6198 12.3265 9.96313 12.1148C11.6584 11.0691 12.8833 10.3137 13.6569 9.52945C14.3999 8.77626 14.7075 8.01134 14.7184 6.8893C14.7186 6.86906 14.7188 6.8487 14.7188 6.82828C14.7188 4.56668 12.8788 2.72672 10.6172 2.72672H6.16406Z" fill="#1E1E1E" fill-opacity="0.6"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_35153_8182">
                      <rect width="15" height="15" fill="white" transform="translate(0.890625 0.5)"/>
                    </clipPath>
                  </defs>
                </svg>
                <span> {{ postDetails?.comments_count || 0 }}</span>
              </div>
              <span>
                &#x2022; {{ momentWrapper(post?.updated_at).fromNow() }}
              </span>
            </div>
            <div>
              <Dropdown
                placement="bottom"
                dropdown-classes="!min-w-[150px]"
                button-classes="flex items-center gap-2"
                :unstyled="true"
              >
                <template v-slot:selected>
                  <span v-tooltip="'Sort by'">
                    {{ selectedSort.label }}
                  </span>
                </template>
                <DropdownItem
                  v-for="option in sortingOptions"
                  :key="option.value"
                  class="flex items-center justify-between"
                  size="sm"
                  :selected="option.value === selectedSort.value"
                  @click="selectedSort = option"
                >
                  {{ option.label }}
                  <template v-if="selectedSort.value === option.value">
                    <img
                      :src="inboxTickMark"
                      alt="selected list icon"
                    />
                  </template>
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
          <hr class="my-0" :class="{ 'mt-4': inboxType === 'review' }"/>

        </div>
        <div class="mx-auto w-full max-w-2xl">
          <CommentBlock
            v-for="comment in commentsList"
            :key="comment._id"
            :comment="comment"
            :can-delete="null"
            :active-inbox-detail="post"
            @like="handleLikeComment"
            @reply="handleReplyComment"
            @delete="handleDeleteComment"
            @hide="handleHideComment"
          />
          <template v-if="isFetchingComments">
            <div class="flex justify-center">
              <clip-loader class="p-3" :color="'#436aff'" :size="'2rem'" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <MessageComposer
      custom-class="!max-w-[650px]"
      :active-inbox-detail="post"
      :allow-note="false"
      @send="$emit('send', $event)"
     />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import MessageComposer from '@src/modules/inbox-revamp/components/MessageComposer.vue'
import externalLinkIcon from '@src/assets/img/analytics/external-link.svg'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import { Dropdown, DropdownItem } from '@contentstudio/ui'
import useDateFormat from '@common/composables/useDateFormat'
import { parseDescriptionHtml } from '@common/lib/helper'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import { useStore } from '@state/base'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import CommentBlock from './CommentBlock.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const { dispatch, getters } = useStore()
const { momentWrapper } = useDateFormat()
const { tooltipHtml } = useComposerHelper()
const { inboxTickMark } = useIcons()
const sortingOptions = [
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
]
const props = defineProps({
  post: {
    type: Object,
    required: true
  },
  platformDetails: {
    type: Object,
    required: true
  }
})

const commentsList = ref([]);
const isFetchingComments = ref(true);
const selectedSort = ref(sortingOptions[0])
const page = ref(1)
const limit = ref(10)
const canLoadMore = ref(true)
const postReadMore = ref(false)

const postDetails = computed(() => {
  return props.post?.element_details || {}
})

const inboxType = computed(() => {
  return props.post?.inbox_type || ''
})
const userDetails = computed(() => {
  return props.post?.inbox_details?.posted_by || {}
})

const postAttachments = computed(() => {
  return postDetails.value?.post_attachment?.[0] || {}
})

const isPostContentLarge = computed(() => {
  return postDetails.value?.post_message?.length > 200
})

const trimmedPostMessage = computed(() => {
  if (postReadMore.value || !isPostContentLarge.value) return postDetails.value?.post_message
  return postDetails.value?.post_message?.substr(0, 200) + '...'
})

onMounted(() => {
  fetchPostComments()
})

watch(
  () => postDetails.value.element_id,
  (newVal, oldVal) => {
    if (newVal === oldVal) {
      return
    }
    postReadMore.value = false
    commentsList.value = []
    fetchPostComments()
  },
)
function navigationNext() {
  return `<i class="fas fa-chevron-right"></i>`
}

function navigationPrev() {
  return `<i class="fas fa-chevron-left"></i>`
}

const getStarLimit = (stars) =>{
  switch (stars) {
    case 'ONE':
      return 1
    case 'TWO':
      return 2
    case 'THREE':
      return 3
    case 'FOUR':
      return 4
    case 'FIVE':
      return 5
    default:
      return 0
  }
}

async function fetchPostComments() {
  try {
    isFetchingComments.value = true
    const payload = {
      post_id: postDetails.value?.element_id,
      workspace_id: getters.getWorkspaces.activeWorkspace._id,
      platform: props.post?.platform,
      sort_order: selectedSort.value.value,
      page: page.value,
      limit: limit.value,
    }
    const res = await dispatch('fetchPostComments', payload)
    console.log('comments le aaya', res)
    if (res.isValid) {
      commentsList.value = res?.comments || []
      canLoadMore.value = res?.can_load_more
    }
    else {
      commentsList.value = []
      canLoadMore.value = false
    }
    isFetchingComments.value = false
  } catch (err) {
    console.log('err', err)
  }
}

const togglePostReadMore = () => {
  postReadMore.value = !postReadMore.value
}
</script>

<style scoped>
  .post-view {
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.05);
  }
</style>
