<template>
  <b-modal
    :id="modalId"
    size="xl"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    @hidden="resetModal"
  >
    <TabImagePreview></TabImagePreview>
    <SideTabs
      :tab-index="tabIndex"
      :type="type"
      @changeTab="changeTab"
      @uploadingChangeTab="uploadingChangeTab"
    >
      <template v-slot:upload>
        <UploadFilesTab
          :modal-id="modalId"
          :type="type"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:library>
        <MediaLibraryTab
          v-if="type !== 'library'"
          :modal-id="modalId"
          :source="source"
          :existing-media="existingMedia"
          :type="type"
          :folders="folderList"
          :files-count="filesCount"
          :is-folder-fetching="isFolderFetching"
          @fetch-folder="fetchFolders"
        />
      </template>
      <template v-slot:direct>
        <DirectUploadFileTab
        :modal-id="modalId"
          :type="type"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:pinterest>
        <FetchMediaUrlTab
          :type="type"
          :modal-id="modalId"
          kind="pinterest"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:facebook>
        <FetchMediaUrlTab
          :type="type"
          :modal-id="modalId"
          kind="facebook"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:flickr>
        <FetchMediaUrlTab
          :type="type"
          :modal-id="modalId"
          kind="flickr"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:pixabay>
        <SearchMediaTab
          :modal-id="modalId"
          :type="type"
          kind="pixabay"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:giphy>
        <SearchMediaTab
          :modal-id="modalId"
          :type="type"
          kind="giphy"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:dropbox>
        <DropBoxMediaTab
          :type="type"
          :modal-id="modalId"
          kind="dropbox"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        />
      </template>
      <template v-slot:google-drive>
        <GoogleDriveAuth
          :type="type"
          :modal-id="modalId"
          :folders="folderList"
          @uploading="handleUploading"
          @changeTab="changeTab"
        ></GoogleDriveAuth>
      </template>
    </SideTabs>

    <header class="modal-header media-library-modal-header">
      <div
        v-if="type !== 'library'"
        class="media-library-header__storage mr-3 mt-2"
      >
        <div>
          <i class="fal fa-cloud"></i>
        </div>
        <div>
          <template v-if="isFetchingLimits">
            <div class="d-flex flex-column">
              <SkeletonBox
                class="mb-1"
                height="8px"
                width="100px"
                radius=".2rem"
              />
              <SkeletonBox width="100px" radius=".2rem" />
            </div>
          </template>
          <template v-else-if="limits !== null">
            <b-progress
              :variant="
                mediaLimits.used >= mediaLimits.total ? 'danger' : 'primary'
              "
              class="mb-1"
              height="8px"
              :value="mediaLimits.used"
              :max="mediaLimits.total"
            ></b-progress>
            {{ bytesToSize(mediaLimits.used) }} of
            {{ bytesToSize(mediaLimits.total) }} used
            <i
              v-b-tooltip.hover.bottomleft
              :title="`This storage is subscribed by ${
                getSuperAdminDetails().full_name
              } and being consumed by all team members.`"
              class="fal fa-info-circle"
            ></i>
          </template>
        </div>
      </div>
      <button type="button" aria-label="Close" class="close" @click="closeModal"
        >×</button
      >
    </header>
  </b-modal>
</template>

<script>
import { swalAttributes } from '@common/constants/common-attributes'
import GoogleDriveAuth from '@src/components/GoogleDriveAuth'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import {EventBus} from "@common/lib/event-bus";
import { commonMethods } from '@common/store/common-methods'
import TabImagePreview from '../components/MediaTabs/TabImagePreview'
import SkeletonBox from '../../../../analytics/views/common/SkeletonBox'
import SideTabs from './SideTabs'
import UploadFilesTab from './MediaTabs/UploadFilesTab'
import MediaLibraryTab from './MediaTabs/MediaLibraryTab'
import DirectUploadFileTab from './MediaTabs/DirectUploadFileTab'
import FetchMediaUrlTab from './MediaTabs/FetchMediaUrlTab'
import SearchMediaTab from './MediaTabs/SearchMediaTab'
import DropBoxMediaTab from './MediaTabs/DropBoxMediaTab'

export default {
  name: 'UploadMediaModal',

  components: {
    TabImagePreview,
    SideTabs,
    UploadFilesTab,
    MediaLibraryTab,
    DirectUploadFileTab,
    FetchMediaUrlTab,
    SearchMediaTab,
    DropBoxMediaTab,
    SkeletonBox
  },
  mixins: [MediaHelperMixin],
  props: {
    type: {
      type: String,
      default: 'social',
    },
    folders: {
      type: Array,
      default: () => []
    },
    isFetchingLimits: {
      type: Boolean,
      default: false
    },
    limits: {
      type: Object,
      default: null
    },
    modalId: {
      type: String,
      default: 'upload-media-modal'
    }
  },
  data() {
    return {
      folderList: [],
      filesCount: {},
      isFolderFetching: false,
      source: null,
      existingMedia: {},
      tabIndex: 0,
      isUploading: false,
      mediaLimits: {},
    }
  },

  watch: {
    folders(newVal, oldVal) {
      if (newVal !== oldVal && this.type === 'library') {
        this.folderList = [...newVal]
      }
    },
  },

  mounted() {
    EventBus.$on('show-media-library-modal', ({source, details, sideTabIndex = 0, modalId = 'upload-media-modal'}) => {
      this.tabIndex = sideTabIndex
      if (this.type !== 'library') {
        this.fetchFolders()
      } else {
        this.folderList = [...this.folders]
      }


      this.source = source
      if (details) {
        this.existingMedia = details
      }
      this.getMediaLimitsHelper((used, total) => {
        this.mediaLimits = {
          used,
          total,
        }
      })
      this.$bvModal.show(modalId)
    })
    this.getMediaLimitsHelper((used, total) => {
      this.mediaLimits = {
        used,
        total,
      }
    })
  },

  beforeUnmount() {
    // Clearing State
    this.folderList = []
    // For Making Upload Modal Global
    // EventBus.$off('show-media-library-modal')
  },

  methods: {
    resetModal() {
      this.tabIndex = 0
      this.isUploading = false
      this.existingMedia = {}
      this.source = null
    },
    handleUploading(val) {
      this.isUploading = val
    },

    async closeModal() {
      if (this.isUploading) {
        const confirm = await this.$bvModal.msgBoxConfirm(
          'Are you sure, you want to cancel the upload?',
          {
            title: 'Remove Folder',
            ...swalAttributes(),
          }
        )

        if (confirm) {
          this.isUploading = false
          this.cancelMediaRequestHelper('mediaLibraryUploadMedia')
          this.$bvModal.hide(this.modalId)
          commonMethods.toggleWidgets(false)
        }
      } else {
        this.$bvModal.hide(this.modalId)
        commonMethods.toggleWidgets(false)
      }
    },

    async changeTab(index, upload = false) {
      console.debug('changeTab', index)
      if(index === 1 && upload === true) {
        this.fetchFolders();
      }
      await this.$nextTick()
      this.tabIndex = index
    },
    async uploadingChangeTab(nxtIndex, prevIndex, $event) {
      if (this.isUploading) {
        $event.preventDefault()
        const confirm = await this.$bvModal.msgBoxConfirm(
          'Are you sure, you want to change tab upload will be cancelled?',
          {
            title: 'Remove Folder',
            ...swalAttributes(),
          }
        )
        if (confirm) {
          this.cancelMediaRequestHelper('mediaLibraryUploadMedia')
          this.isUploading = false
          this.tabIndex = nxtIndex
        }
      } else {
        this.tabIndex = nxtIndex
      }
    },

    async fetchFolders() {
      this.isFolderFetching = true

      await this.fetchAllFoldersHelper(true, (status, folders) => {
        if (status) {
          this.folderList = [...folders]
        } else {
          this.folderList = []
        }
      })

      await this.fetchMediaStatsHelper((status, stats) => {
        if (status) {
          this.filesCount = { ...stats }
        } else {
          this.filesCount = {}
        }
      })

      this.isFolderFetching = false
    },
    getSuperAdminDetails() {
      const teamMembers = this.getWorkspaces.activeWorkspace.members
      for (let i = 0; i < teamMembers.length; i++) {
        const member = teamMembers[i]
        if (member.role === 'super_admin') {
          return member.user
        }
      }
      return null
    },
  },
}
</script>
