<template>
  <div>
    <b-modal
      :id="modalId"
      dialog-class="fixed inset-0 flex max-w-full my-0 p-9"
      content-class="bg-white"
      body-class="flex h-full"
      hide-header
      hide-footer
      @shown="handleModalShown"
      @hidden="handleModalHidden"
    >
      <!-- Main content container -->
      <div class="flex w-full h-full p-8 gap-x-4">
        <!-- Left side - Image preview -->
        <div class="flex-1 bg-[#353535] relative rounded-xl">
          <!-- Media content -->
          <div class="relative h-full">
            <video
              v-if="isVideo()"
              ref="videoPlayer"
              class="w-full h-full object-contain"
              controls
            >
              <source
                :src="videoPlayableLink"
                :type="
                  mediaType === 'video/quicktime' ||
                  mediaType === 'video/x-msvideo'
                    ? 'video/mp4'
                    : mediaType
                "
              />
              Your browser does not support the video tag.
            </video>
            <img
              v-else
              class="w-full h-full object-contain"
              :src="mediaLink"
              alt="media-asset"
            />

            <!-- Navigation buttons -->
            <div
              class="absolute inset-x-0 top-1/2 -translate-y-1/2 flex justify-between px-16 "
            >
              <div class="w-10 h-10 2xl:w-12 2xl:h-12">
                <button
                  v-if="!disableLeft"
                  v-tooltip="'Go to previous image'"
                  class="w-10 h-10 2xl:w-12 2xl:h-12 flex items-center justify-center bg-gray-900/75 hover:bg-gray-900/90 hover:scale-105 rounded-xl text-white"
                  @click="previousImage"
                >
                  <i class="fa fa-chevron-left text-base 2xl:text-xl"></i>
                </button>
              </div>
              <div class="w-10 h-10 2xl:w-12 2xl:h-12">
                <button
                  v-if="!disableRight"
                  v-tooltip="'Go to next image'"
                  class="w-10 h-10 2xl:w-12 2xl:h-12 flex items-center justify-center bg-gray-900/75 hover:bg-gray-900/90 hover:scale-105 rounded-xl text-white"
                  @click="nextImage"
                >
                  <i class="fa fa-chevron-right text-base 2xl:text-xl"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Right side - File information -->
        <div
          class="w-full max-w-[25rem] 2xl:max-w-[30rem] border-l border-gray-200 flex flex-col gap-6"
        >
          <!-- Close button -->
          <button
            class="absolute top-3 right-4 z-10 text-gray-600 hover:text-gray-800 cursor-pointer"
            @click="$bvModal.hide(modalId)"
          >
            <i class="fa fa-times text-xl"></i>
          </button>

          <div
            class="p-2 2xl:p-4 border border-[#ebebeb] rounded-xl h-full flex flex-col justify-between"
          >
            <div class="space-y-4 2xl:space-y-8 py-2 2xl:py-4 px-2">
              <!-- Header with user info -->
              <div class="flex items-center space-x-3">
                <img
                  :src="currentMedia?.user?.image"
                  class="w-12 h-12 rounded-full"
                  @error="socialAccountImgFallback"
                />
                <div>
                  <p
                  class="text-[15px] font-medium text-gray-900">
                    <span class="font-semibold text-gray-900">{{
                      currentMedia?.user?.full_name
                    }}</span>
                    uploaded this file
                  </p>
                  <p class="text-[13px] text-gray-700">
                    {{ momentWrapper(currentMedia?.created_at).fromNow() }}
                  </p>
                </div>
              </div>

              <!-- File info tab -->
              <SegmentedControl
                :model-value="currentTab"
                size="sm"
                radius="xl"
                :data="[
                  { value: 'file-info', label: 'File Info' },
                  {
                    value: 'usage',
                    label: `Usage ${currentMedia?.usage_data?.planIds?.length > 0 ? `(${currentMedia?.usage_data?.planIds?.length})` : ''}`,
                  },
                ]"
                @update:model-value="(value) => (currentTab = value)"
              />

              <!-- File information content -->
              <div v-if="currentTab === 'file-info'" class="p-4 space-y-4">
                <div class="flex items-center pb-3 file-property">
                  <p class="text-sm font-medium w-[40%]">Name</p>
                  <p class="text-sm w-[60%]">{{ mediaName }}</p>
                </div>
                <div class="flex items-center pb-3 file-property">
                  <p class="text-sm font-medium w-[40%]">Type</p>
                  <p class="text-sm w-[60%]">{{ mediaType }}</p>
                </div>
                <div class="flex items-center pb-3 file-property">
                  <p class="text-sm font-medium w-[40%]">Size</p>
                  <p class="text-sm w-[60%]">{{ mediaSize }}</p>
                </div>
                <div class="flex items-center pb-3 file-property">
                  <p class="text-sm font-medium w-[40%]">Created on</p>
                  <p class="text-sm w-[60%]">
                    {{
                      momentWrapper(currentMedia?.created_at).formatDateTime()
                    }}
                  </p>
                </div>
                <div v-if="mediaDimension" class="flex items-center pb-3 file-property">
                  <p class="text-sm font-medium w-[40%]">Dimensions</p>
                  <p class="text-sm w-[60%]">
                    <span>{{ mediaDimension  }}</span>
                  </p>
                </div>
              </div>

              <!-- Usage content -->
              <div
                v-if="currentTab === 'usage'"
                class="p-1 space-y-2 2xl:space-y-4 h-full min-h-[30vh] max-h-[40vh] 2xl:max-h-[45vh] overflow-y-auto"
              >
                <!-- Loading Skeleton -->
                <div v-if="isLoading">
                  <div class="animate-pulse flex space-x-4">
                    <div class="flex-1 space-y-3 py-1">
                      <div class="h-2 bg-gray-200 rounded"></div>
                      <div class="space-y-2">
                        <div class="grid grid-cols-3 gap-3">
                          <div class="h-2 bg-gray-200 rounded col-span-2"></div>
                          <div class="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- No usage found -->
                <div
                  v-if="!usagePlans.length && !isLoading"
                  class="p-2 bg-gray-100 rounded-xl"
                >
                  <p class="text-sm font-medium text-center">
                    No usage found
                  </p>
                </div>

                <div
                  v-for="plan in usagePlans"
                  v-else
                  :key="plan?._id"
                  v-tooltip="{
                    content: !hoveredAccount ? 'View this post in planner' : '',
                    maxWidth: !hoveredAccount ? 350 : 0,
                    arrow: !hoveredAccount,
                  }"
                  class="flex items-center justify-between p-2 2xl:p-3 file-property hover:bg-blue-50 hover:rounded-xl cursor-pointer"
                  @click.self="OpenPlanFromUsage(plan?._id)"
                >
                  <!-- Accounts -->
                  <div class="flex -space-x-2 2xl:-space-x-3">
                    <span
                      v-if="!postAccounts(plan)?.length"
                      class="text-sm font-medium"
                    >
                      No accounts
                    </span>
                    <div
                      v-for="account in postAccounts(plan)?.slice(0, 2)"
                      :key="account?._id"
                      v-tooltip="{
                        content: account?.name,
                      }"
                      class="relative"
                      @mouseover="hoveredAccount = true"
                      @mouseout="hoveredAccount = false"
                    >
                      <img
                        :src="account?.image"
                        alt=""
                        class="w-8 h-8 2xl:w-10 2xl:h-10 rounded-full border border-white"
                        @error="socialAccountImgFallback"
                      />
                      <img
                        class="w-5 h-5 2xl:w-6 2xl:h-6 absolute bottom-0 right-0 rounded-full"
                        :src="
                          require(`@assets/img/integration/${account?.type}-rounded.svg`)
                        "
                        alt="social icon"
                      />
                    </div>

                    <CstDropdown
                      v-if="postAccounts(plan)?.length > 2"
                      :dropdown-classes="{
                        '!w-60 !left-0 !mb-1': true,
                        'h-96 overflow-y-auto': postAccounts(plan).length > 10,
                      }"
                      button-classes="!border-none hover:!border-none !p-0 !gap-0 !bg-transparent"
                    >
                      <template v-slot:selected>
                        <span
                          class="inline-flex justify-center items-center w-9 h-9 bg-gray-400 rounded-full text-xs"
                        >
                          +{{ postAccounts(plan)?.length - 2 }}
                        </span>
                      </template>
                      <template v-slot:arrow>
                        <span></span>
                      </template>

                      <template
                        v-for="(dropAcc, accKey) in postAccounts(plan).slice(2)"
                        :key="accKey"
                      >
                        <CstDropdownItem>
                          <div class="flex items-center gap-x-3">
                            <span class="relative inline-block mr-1.5">
                              <img
                                :src="dropAcc.image"
                                alt=""
                                class="rounded-full object-cover border pointer-events-none w-9 h-9"
                                data-fallback="0"
                                @error="socialAccountImgFallback"
                              />
                              <img
                                :src="getSocialImageRounded(dropAcc.platform)"
                                :alt="dropAcc.platform"
                                class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                              />
                            </span>
                            <span class="text-sm">
                              {{
                                truncateString(
                                  'platform_name' in dropAcc
                                    ? dropAcc.platform_name
                                    : dropAcc.name,
                                  30,
                                )
                              }}
                            </span>
                          </div>
                        </CstDropdownItem>
                      </template>
                    </CstDropdown>
                  </div>

                  <!-- Status & Date -->
                  <div
                    class="flex items-center justify-between gap-x-4 2xl:gap-x-8 "
                    @click.stop="OpenPlanFromUsage(plan?._id)"
                  >
                    <span
                      class="text-xs 2xl:text-sm font-medium px-2 2xl:px-3 py-[0.3rem] 2xl:py-[0.4rem] rounded-[6.25rem] capitalize inline-flex items-center justify-center text-nowrap"
                      :class="postStatusBadgeClasses(plan)"
                      >{{ plan?.status }}</span
                    >
                    <span class="text-xs 2xl:text-sm font-normal">
                      {{
                        momentWrapper(
                          plan?.execution_date_time,
                        ).formatDateTime()
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Action buttons -->
            <div
              class="mt-auto 2xl:p-4 flex items-center justify-center space-x-3"
            >
              <!-- Download -->
              <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="'Download'"
                  class="h-12 w-12 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#56c288] group"
                  @click="downloadMedia"
                >
                  <img
                    v-if="!isDownloading"
                    class="w-5 h-5 group-hover:hidden"
                    src="@src/assets/img/icons/download-colored.svg"
                    alt="Download"
                  />
                  <img
                    v-if="!isDownloading"
                    class="w-5 h-5 hidden group-hover:block"
                    src="@src/assets/img/icons/download-white.svg"
                    alt="Download"
                  />
                  <clip-loader
                    v-else
                    :color="'#374151'"
                    :size="'16px'"
                    class="spinner"
                  />
                </button>
              </div>

              <!-- Compose -->
              <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="'Compose post'"
                  class="h-12 w-12 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#2a6ffb] group"
                  @click="composePost"
                >
                  <img
                    class="w-5 h-5 group-hover:hidden"
                    src="@src/assets/img/icons/compose-colored.svg"
                    alt="Compose"
                  />
                  <img
                    class="w-5 h-5 hidden group-hover:block"
                    src="@src/assets/img/icons/compose-white.svg"
                    alt="Compose"
                  />
                </button>
              </div>

              <!-- Copy -->
              <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="isCopied ? 'Copied' : 'Copy link'"
                  class="h-12 w-12 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#ff922b] group"
                  :class="{
                    '!bg-[#56c288]': isCopied,
                  }"
                  @click="copyMediaLink(mediaLink)"
                >
                  <i
                    v-if="isCopied"
                    class="fas text-md font-normal fas fa-check  text-white"
                  />
                 <template v-else>
                  <img
                    class="w-5 h-5 group-hover:hidden"
                    src="@src/assets/img/icons/copy-colored.svg"
                    alt="Copy"
                  />
                  <img
                    class="w-5 h-5 hidden group-hover:block"
                    src="@src/assets/img/icons/copy-white.svg"
                    alt="Copy"
                  />
                 </template>
                </button>
              </div>

              <!-- Delete -->
              <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="'Delete'"
                  class="h-12 w-12 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#f5144c] group"
                  @click="deleteMedia"
                >
                  <img
                    class="w-5 h-5 group-hover:hidden"
                    src="@src/assets/img/icons/delete-colored.svg"
                    alt="Delete"
                  />
                  <img
                    class="w-5 h-5 hidden group-hover:block"
                    src="@src/assets/img/icons/delete-white.svg"
                    alt="Delete"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script setup>
import { ref, computed, nextTick, watch } from 'vue'
import { SegmentedControl } from '@contentstudio/ui'
import { useStore } from '@state/base'
import { socialChannelsNameArray } from '@common/constants/common-attributes'
import isEmpty from 'is-empty'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import { commonMethods } from '@common/store/common-methods'
import usePlannerActions from '@/src/modules/planner_v2/composables/usePlannerActions'
import useDateFormat from '@/src/modules/common/composables/useDateFormat'
import { fetchPlansURL } from '@/src/modules/planner/config/api-utils'
import proxy from '@/src/modules/common/lib/http-common'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import CstDropdown from '@/src/components/UI/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@/src/components/UI/Dropdown/IconDropdownItem.vue'

const props = defineProps({
  currentMedia: {
    type: Object,
    default: () => ({}),
  },
  disableLeft: {
    type: Boolean,
    default: false,
  },
  disableRight: {
    type: Boolean,
    default: false,
  },
  modalId: {
    type: String,
    default: 'preview-media-asset-modal',
  },
})

const emit = defineEmits(['next-image', 'share', 'delete', 'previous-image'])

// Refs
const shareLoader = ref(false)
const videoPlayer = ref(null)
const isCopied = ref(false)
const isDownloading = ref(false)
const currentTab = ref('file-info')
const usagePlans = ref([])
const isLoading = ref(false)
const accountHovered = ref(false)
const hoveredAccount = ref(false)

// Composables
const { momentWrapper } = useDateFormat()
const { getSocialImageRounded } = useComposerHelper()
const { postStatusBadgeClasses } = usePlannerHelper()
const { OpenPlanFromUsage } = usePlannerActions()
const { dispatch, getters } = useStore()

// Watch for media changes
watch(
  () => props.currentMedia,
  async () => {
    usagePlans.value = []
    if (!props.currentMedia?.usage_data?.planIds?.length) return
    await fetchUsagePlans()
  }
)

// Computed properties
const mediaLink = computed(() => {
  return props.currentMedia?.extension === 'pdf'
    ? props.currentMedia?.thumbnails?.small
    : props.currentMedia?.link
})

const videoPlayableLink = computed(() => props.currentMedia?.converted_video)
const mediaName = computed(() => props.currentMedia?.name)
const mediaType = computed(() => props.currentMedia?.mime_type)
const mediaSize = computed(() => bytesConversion(props.currentMedia?.size))

 /**
     * Getter for media dimension
     * @type {ComputedRef<unknown>}
     */
     const mediaDimension = computed(() => {
      if (
        isVideo(mediaType) &&
        props.currentMedia?.width &&
        props.currentMedia?.height
      ) {
        return `${props.currentMedia?.width} x ${props.currentMedia?.height}`
      } else if (props.currentMedia?.w && props.currentMedia?.h) {
        return `${props.currentMedia?.w} x ${props.currentMedia?.h}`
      }
      return null
    })

// Methods

// Fetch usage plans
const fetchUsagePlans = async () => {
  const options = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    specific_plans: props.currentMedia.usage_data?.planIds,
    source: 'web',
    route_name: 'media_usage',
  }
  try {
    isLoading.value = true
    const response = await proxy.post(fetchPlansURL, options)
    if (response.data.status) {
      usagePlans.value = response.data.plans
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

// Get platforms from account selection
const getPlatforms = (platforms) => {
  return socialChannelsNameArray.filter(
    (platform) => !isEmpty(platforms[platform])
  )
}

// Get post accounts
const postAccounts = (item) => {
  if (item.account_selection) {
    const social = getPlatforms(item.account_selection)
    const accounts = []
    social.forEach((platform) => {
      item.account_selection[platform].forEach((account) => {
        accounts.push({ ...account, platform })
      })
    })
    return accounts.flat()
  }
  return []
}

/**
 * Social account image fallback if there's no image.
 * @param event
 */
const socialAccountImgFallback = (event) => {
  event.target.src =
    'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
}

const isVideo = () => mediaType.value?.includes('video/') || false

const composePost = () => {
  shareLoader.value = true
  emit('share', props.currentMedia)
}

const copyMediaLink = async (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()

  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(str)
      document.body.removeChild(el)
      isCopied.value = true
      setTimeout(() => {
        isCopied.value = false
      }, 3000)
      return true
    }
  } catch (err) {
    console.error('Failed to copy:', err)
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject('The Clipboard API is not available.')
}

const deleteMedia = () => {
  emit('delete', props.currentMedia._id, props.currentMedia.link)
}

const bytesConversion = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  const value = bytes / Math.pow(1024, i)
  return Math.round(value * 100) / 100 + ' ' + sizes[i]
}

const handleKeyDown = (e) => {
  if (e.keyCode === 37) previousImage()
  if (e.keyCode === 39) nextImage()
}

const handleModalShown = () => {
  commonMethods.toggleWidgets(true)
  window.addEventListener('keydown', handleKeyDown, null)
}

const handleModalHidden = () => {
  commonMethods.toggleWidgets(false)
  window.removeEventListener('keydown', handleKeyDown)
  shareLoader.value = false
  currentTab.value = 'file-info'
  isCopied.value = false
}

const nextImage = async () => {
  emit('next-image')
  await nextTick()
  videoPlayer.value?.load()
}

const previousImage = async () => {
  emit('previous-image')
  await nextTick()
  videoPlayer.value?.load()
}

const downloadMedia = async () => {
  try {
    if (!props.currentMedia?.link) return
    isDownloading.value = true
    const response = await fetch(props.currentMedia.link)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = props.currentMedia.name || 'download'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Download failed:', error)
  } finally {
    isDownloading.value = false
  }
}
</script>

<style lang="scss">
.current-media {
  max-width: 65%;
  max-height: 85vh;
  box-shadow: 1px 1px 15px #000000;
}
.segment-item {
  &:hover {
    border: 1px solid rgb(74, 74, 74, 0.2);
  }
}
.file-property {
  border-bottom: 1px solid #ebebeb;
}
</style>
