<template>
  <div class="flex flex-col flex-1 bg-[#f4f7fa] py-8 px-6 overflow-hidden">
    <div
      v-infinite-scroll="[fetchMoreConversation, { distance: 30, canLoadMore, direction: 'top' }]"
      class="flex-1 flex flex-col-reverse overflow-y-auto"
      >
      <template v-for="(date, index) in conversationDates" :key="index">
        <template v-for="(message) in conversation[date]" :key="message._id">
          <template v-if="isAnAction(message) && !isNoteAction(message)">
            <ChatActionUpdate :message="message" />
          </template>
          <template v-else-if="isNoteAction(message)">
            <ChatNoteBubble :message="message" />
          </template>
          <template v-else>
            <ChatBubble :message="message" :is-message-mine="isMessageMine(message)" />
          </template>
        </template>
        <!-- date splitter -->
        <DateDivider :date="date" />
      </template>
      <div v-if="isFetchingConversation" class="flex justify-center items-center">
        <clip-loader class="p-3" :color="'#436aff'" :size="'2rem'" />
      </div>
      <div v-if="!canLoadMore" class="flex justify-center items-center">
        <p>Start of conversation</p>
      </div>
    </div>
    <MessageComposer :active-inbox-detail="chat" @send="handleMessageSend"/>
  </div>
</template>

<script setup>
import { computed,ref, watch } from 'vue'
import { vInfiniteScroll } from '@vueuse/components'
import MessageComposer from '@src/modules/inbox-revamp/components/MessageComposer.vue'
import { useStore } from '@state/base'
import useDateFormat from '@common/composables/useDateFormat'
import { set } from 'lodash'
import ChatActionUpdate from '@src/modules/inbox-revamp/components/ChatActionUpdate.vue'
import ChatNoteBubble from '@src/modules/inbox-revamp/components/ChatNoteBubble.vue'
import DateDivider from '@src/modules/inbox-revamp/components/DateDivider.vue'
import ChatBubble from '@src/modules/inbox-revamp/components/ChatBubble.vue'

const { momentWrapper } = useDateFormat()
const { dispatch, getters } = useStore()

const props = defineProps({
  chat: {
    type: Object,
    required: true
  },
  platformDetails: {
    type: Object,
    required: true
  }
});

const isFetchingConversation = ref(false)
const conversation = ref({})
const page = ref(1)
const limit = ref(20)
const canLoadMore = ref(true)

const recipientId = computed(() => {
  return props.chat?.inbox_details?.posted_by?.user_id || ''
})

const postDetails = computed(() => {
  return props.chat?.element_details || {}
})

const conversationDates = computed(() => {
  return Object.keys(conversation.value)
})

// const inboxType = computed(() => {
//   return props.chat?.inbox_type || ''
// })
// const userDetails = computed(() => {
//   return props.chat?.inbox_details?.posted_by || {}
// })

watch(() => props.chat?.element_details?.element_id, () => {
  conversation.value = {}
  page.value = 1
  canLoadMore.value = true
  fetchConversation()
}, { immediate: true })

async function fetchConversation(append = false) {
  try {
    isFetchingConversation.value = true
    const payload = {
      conversation_id: postDetails.value?.element_id,
      workspace_id: getters.getWorkspaces.activeWorkspace._id,
      platform: props.chat?.platform,
      sort_order: 'desc',
      page: page.value,
      limit: limit.value,
    }

    const res = await dispatch('fetchMessages', payload)
    console.log('messages le aaya', res)
    if (append) {
      Object.entries(res?.messages || {}).forEach(([date, messages]) => {
        if (conversation.value[date]) {
          conversation.value[date].push(...messages);
        } else {
          conversation.value[date] = messages;
        }
      });
    } else {
      conversation.value = res?.messages || {};
    }
    if (!res?.messages || Object.keys(res.messages).length === 0) {
      canLoadMore.value = false
    }
    // canLoadMore.value = res?.can_load_more
    isFetchingConversation.value = false
  } catch (err) {
    console.log('err', err)
  }
}
const fetchMoreConversation = () => {
  if (canLoadMore.value && !isFetchingConversation.value) {
    page.value++
    fetchConversation(true)
  }
}

const isMessageMine = (message) => {
  return message.from?.[0]?.id !== recipientId.value
}

const generateUnsentMessage = (message) => {
  const [firstName, ...lastNameParts] = props.platformDetails?.platform_name.split(' ')
  const lastName = lastNameParts.join(' ')
  return {
    _id: Date.now(),
    message,
    isMessageSending: true,
    created_time: momentWrapper().utc(),
    from: [
      {
        id: props.platformDetails?.platform_id,
        first_name: firstName,
        last_name: lastName,
        profile_pic: props.platformDetails?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
        platform_type: props.platformDetails?.platform_type,
        created_at: momentWrapper().utc()
      }
    ]
  }
}

const generateUnsentNote = (message) => {
  const firstName = getters.getProfile?.firstname
  const lastName = getters.getProfile?.lastname
  return {
    _id: Date.now(),
    message,
    isMessageSending: true,
    created_time: momentWrapper().utc(),
    action: {
      type: 'NOTE',
      action_performed_by: {
        id: getters.getProfile?._id,
        user_name: `${firstName} ${lastName}`,
        user_image: getters.getProfile?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
      }
    }
  }
}

const generateUnsentMediaMessage = (file) => {
  const [firstName, ...lastNameParts] = props.platformDetails?.platform_name.split(' ')
  const lastName = lastNameParts.join(' ')
  const type = file?.type.split('/')[0]
  const mediaKey = `${type}_url`

  if (!file) return
  return {
    _id: Date.now(),
    isMessageSending: true,
    created_time: momentWrapper().utc(),
    attachments:[
      {
        id: file?.id,
        file_name: file?.file_name,
        file_type: file?.file_type,
        [mediaKey]: URL.createObjectURL(file),
        type,
      }
    ],
    from: [
      {
        id: props.platformDetails?.platform_id,
        first_name: firstName,
        last_name: lastName,
        profile_pic: props.platformDetails?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
        platform_type: props.platformDetails?.platform_type,
        created_at: momentWrapper().utc()
      }
    ]
  }
}

const handleMessageSend = (message) => {
  if (message?.isNote) {
    addNote(message)
  } else {
    if(message?.text?.length > 0) {
      addMessage(message)
    }
    if (message?.attachment) {
      addMediaMessage(message)
    }
  }
}
/**
 * Add a new message to the conversation.
 * @param {Object} message - The message object.
 * @param {String} message.text - The text of the message.
 */
const addMessage = async (message) => {
  const messageObj = generateUnsentMessage(message.text)
  const currentDate = momentWrapper().format('YYYY-MM-DD');
  if (!(currentDate in conversation.value)) {
    conversation.value = { [currentDate]: [], ...conversation.value }
  }
  conversation.value[currentDate].unshift(messageObj);

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.chat?.platform_id,
    platform_type: props.chat?.platform,
    recipient_id: recipientId.value,
    element_id: postDetails.value?.element_id,
    message: message.text,
  }
  const res = await dispatch('sendTextMessage', payload)
  if (res?.isValid) {
    const newMessage = res.message?.data?.payload
    replaceMessage(messageObj, newMessage, currentDate)
  }
  else {
    messageObj.isMessageSending = false
    messageObj.error = true
    messageObj.errorMessage = res.message
    replaceMessage(messageObj, messageObj, currentDate)
  }
};

/**
 * Add a note to the conversation.
 * @param {Object} message - The message object.
 * @param {String} message.text - The text of the message.
 */
const addNote = async (message) => {
  const messageObj = generateUnsentNote(message.text)
  const currentDate = momentWrapper().format('YYYY-MM-DD');
  if (!(currentDate in conversation.value)) {
    conversation.value = { [currentDate]: [], ...conversation.value }
  }
  conversation.value[currentDate].unshift(messageObj);

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.chat?.platform_id,
    platform_type: props.chat?.platform,
    conversation_id: postDetails.value?.element_id,
    message: message.text,
  }
  const res = await dispatch('addNoteToChat', payload)
  if (res?.isValid) {
    const newMessage = res?.note
    replaceMessage(messageObj, newMessage, currentDate)
  }
  else {
    messageObj.isMessageSending = false
    messageObj.error = true
    messageObj.errorMessage = res.message
    replaceMessage(messageObj, messageObj, currentDate)
  }
}

/**
 * Add a media message to the conversation.
 * @param {Object} message - The message object.
 * @param {String} message.attachment - The attachment of the message.
 */
 const addMediaMessage= async (message) => {
  const messageObj = generateUnsentMediaMessage(message.attachment)
  const currentDate = momentWrapper().format('YYYY-MM-DD');
  if (!(currentDate in conversation.value)) {
    conversation.value = { [currentDate]: [], ...conversation.value }
  }
  conversation.value[currentDate].unshift(messageObj);

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.chat?.platform_id,
    platform_type: props.chat?.platform,
    element_id: postDetails.value?.element_id,
    recipient_id: recipientId.value,
    file: message.attachment,
    file_type: message.attachment.type.split('/')[0]
  }
  const res = await dispatch('sendMediaMessage', payload)
  if (res?.isValid) {
    const newMessage = res?.message
    replaceMessage(messageObj, newMessage, currentDate)
  }
  else {
    messageObj.isMessageSending = false
    messageObj.error = true
    messageObj.errorMessage = res.message
    replaceMessage(messageObj, messageObj, currentDate)
  }
}

/**
 * Replace a message in the conversation object with a new one.
 * @param {Object} message - The old message object
 * @param {Object} newMessage - The new message object
 * @param {String} date - The date of the conversation
 */
const replaceMessage = (message, newMessage, date) => {
  // Find the index of the message to replace in the conversation array
  const index = conversation.value[date].findIndex((item) => item._id === message._id)
  // Replace the old message with the new one using Lodash's set() method
  set(conversation.value[date], index, { ...newMessage })
}

const isAnAction = (message) => {
  // message.action key exists and is not null or undefined
  return !!message?.action;
}

const isNoteAction = (message) => {
  return message?.action?.type === 'NOTE'
}

</script>
