<template>
  <div>
    <IndividualCoverPage
      v-if="type !== 'group'"
      :accounts="accounts"
      :report-date="reportDate"
      :type="type"
    />
    <BaseCoverPage v-for="(page, pageIndex) in accountPages" v-else :key="pageIndex" :show-company-info="pageIndex === 0">
      <!-- Page Title for continuation pages -->
      <div v-if="pageIndex > 0" class="flex items-center justify-between text-center mb-8">
        <div v-if="company_logo" class="flex items-center justify-center text-center my-8">
          <img :src="company_logo" alt="" class="company-logo" />
          <h3 class="company-name">{{ company_name }}</h3>
        </div>
        <div class="flex flex-col items-center">
          <h3>{{ getReportName }}</h3>
          <div class="date-range  mx-auto">{{ getReportDate }}</div>
        </div>
      </div>

      <!-- Report Title Section - Only on first page -->
      <div v-else class="report-title text-center mb-8">
        <h1 class="mb-3">{{ getReportName }}</h1>
        <div class="date-range  mx-auto rounded-full">{{ getReportDate }}</div>
      </div>

      <!-- Accounts Grid Section -->
      <div class="accounts-grid">
        <div class="account-grid-container">
          <template v-for="account in page" :key="account.id">
            <div class="account-item">
              <div class="account-icon">
                <img
                  :src="account?.platform_logo || account?.image"
                  :alt="account?.name"
                  class="profile-image"
                  @error="handleImageError"
                />
                <img
                  :src="getSocialImageRounded(account?.account_type)"
                  :alt="account?.account_type"
                  class="platform-icon"
                />
              </div>
              <div class="account-name">{{ account?.name || account?.platform_name }}</div>
            </div>
          </template>
        </div>
      </div>
    </BaseCoverPage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import useDateFormat from "@common/composables/useDateFormat";
import IndividualCoverPage from './IndividualCoverPage.vue'
import BaseCoverPage from './BaseCoverPage.vue'

export default {
  components: {
    IndividualCoverPage,
    BaseCoverPage
  },
  props: {
    reportDate: {
      type: String,
      default: '',
    },
    accounts: {
      type: [Array, Object],
      default: () => [],
    },
    type: {
      type: String,
      default: 'Twitter',
    },
  },
  setup() {
    const { handleImageError, getSocialImageRounded } = useComposerHelper()
    const { momentWrapper } = useDateFormat()

    return {
      handleImageError,
      getSocialImageRounded,
      momentWrapper,
    }
  },
  data() {
    return {
      showCompanyInfo: false,
      company_logo: '',
      company_name: '',
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),

    getReportDate() {
      if (!this.reportDate) return

      const splitDates = this.reportDate.split(' - ')
      if (splitDates.length < 2) return
      const formattedStartDate = this.momentWrapper(splitDates[0]).formatTimezone().formatDate()
      const formattedEndDate = this.momentWrapper(splitDates[1]).formatTimezone().formatDate()

      return `${formattedStartDate} - ${formattedEndDate}`
    },
    getReportName() {
      if (!this.type) return
      if(this.type === 'campaign-and-label') return 'Campaigns & Labels Analytics';
      else if(this.type === 'twitter') return 'X (Twitter) Analytics';
      else if(this.type === 'individual') return 'Individual Overview Report';
      else if(this.type === 'group') return 'Grouped Overview Report';
      const format = (
        this.type.charAt(0).toUpperCase() + this.type.slice(1)
      ).replace(/-/g, ' ')

      return `${format} Analytics`
    },
    accountPages() {
      if (!this.accounts || !Array.isArray(this.accounts)) {
        return [];
      }

      const ACCOUNTS_PER_PAGE = 24; // 6 columns x 4 rows
      const pages = [];

      for (let i = 0; i < this.accounts.length; i += ACCOUNTS_PER_PAGE) {
        pages.push(this.accounts.slice(i, i + ACCOUNTS_PER_PAGE));
      }

      return pages;
    }
  },
  mounted() {
    this.company_logo = this.getActiveWorkspace.company_logo
    this.company_name = this.getActiveWorkspace.company_name
  }
}
</script>

<style lang="less" scoped>
.report-title {
  margin: 3rem 0;

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: #333;
  }
  .date-range {
    margin-top: 1rem;
    font-size: 18px;
    width: fit-content;
    border: 2px solid #333;
    color: #333;
    padding: 0.5rem 2rem;
  }
}

.accounts-grid {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;

  .account-grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
    justify-items: center;
  }

  .account-item {
    text-align: center;
    width: 100%;

    .account-icon {
      position: relative;
      width: 60px;
      height: 60px;
      margin: 0 auto 8px;

      .profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .platform-icon {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid white;
      }
    }

    .account-name {
      font-size: 12px;
      color: #333;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
      margin: 0 auto;
    }
  }
}

.generated-date {
  margin-top: 3rem;
  p {
    font-size: 14px;
    color: #666;
  }
}

.continuation-title {

  h2 {
    font-size: 28px;
    font-weight: 500;
    color: #333;
  }

  .page-number {
    margin-top: 0.5rem;
    font-size: 16px;
    color: #666;
  }
}
</style>
