<template>
  <div class="flex items-center mb-2 px-2">
    <div class="flex-1 h-px bg-gray-300"></div>
    <div class="flex items-center justify-center leading-none h-8 text-sm px-3 rounded-full cstu-bg-blue-50 cstu-border-blue-400 border-[1px] !border-opacity-20 mx-4 text-center whitespace-nowrap">
      {{ formattedDate }}
    </div>
    <div class="flex-1 h-px bg-gray-300"></div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import useDateFormat from '@common/composables/useDateFormat'

const { momentWrapper } = useDateFormat()

const props = defineProps({
  date: {
    type: [String, Date],
    required: true
  }
});

/**
 * Format a date string to display in the chat.
 * @param {String} date - The date string to format
 * @returns {String} - The formatted date string
 */
const getFormattedDate = (date) => {
  return momentWrapper(date).formatDate();
}

const formattedDate = computed(() => getFormattedDate(props.date));
</script>
