<template>
  <div class="flex flex-col h-full bg-white border-x-only">
    <!-- Search and filter -->
    <div class="flex items-center justify-between gap-3 px-4 py-3 bottom-border-only h-[4.25rem]">
      <div
        v-show="!isSearchActive && !searchText"
        class="flex items-center gap-3"
      >
        <SegmentedControl
          v-if="shouldShowFilter(routeBasedFiltersKeys.segmentedControl)"
          :model-value="segmentedConvoFilter"
          size="sm"
          radius="lg"
          :data="[
            { value: 'all', label: 'All' },
            { value: 'read', label: 'Read' },
            { value: 'unread', label: `Unread (${unreadCount})` },
          ]"
          @update:model-value="(value) => {
            segmentedConvoFilter = value
            }"
        />
        <span v-if="shouldShowFilter(routeBasedFiltersKeys.title)" class="text-lg font-medium capitalize">
          {{ routeTitle }}
        </span>
        <template v-if="shouldShowFilter(routeBasedFiltersKeys.assignedTo)">
          <TeamMembersSelect
            :model-value="selectedAssingedTo"
            :members="getters.getWorkspaceInboxTeamMembers"
            :title="'Assigned To'"
            @update:model-value="updateAssingedTo"
          />
        </template>
        <template v-if="shouldShowFilter(routeBasedFiltersKeys.assignedBy)">
          <TeamMembersSelect
            :model-value="selectedAssingedBy"
            :members="getters.getWorkspaceInboxTeamMembers"
            :title="'Assigned By'"
            @update:model-value="updateAssingedBy"
          />
        </template>
        <template v-if="shouldShowFilter(routeBasedFiltersKeys.markedDoneBy)">
          <TeamMembersSelect
            :model-value="selectedMarkedDoneBy"
            :members="getters.getWorkspaceInboxTeamMembers"
            :title="'Marked Done By'"
            @update:model-value="updateMarkedDoneBy"
          />
        </template>
        <template v-if="shouldShowFilter(routeBasedFiltersKeys.archivedBy)">
          <TeamMembersSelect
            :model-value="selectedArchivedBy"
            :members="getters.getWorkspaceInboxTeamMembers"
            :title="'Archived By'"
            @update:model-value="updateArchivedBy"
          />
        </template>

      </div>
      <!-- Search -->
      <div
        id="search"
        :class="[
          'ml-auto flex items-center px-3 gap-3 cstu-bg-gray-100 rounded-lg transition-all duration-300 h-[41px]',
          isSearchActive || searchText ? 'flex-1' : 'w-[100px]',
        ]">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M6.52148 11.332C9.21387 11.332 11.3965 9.14942 11.3965 6.45703C11.3965 3.76464 9.21387 1.58203 6.52148 1.58203C3.8291 1.58203 1.64648 3.76464 1.64648 6.45703C1.64648 9.14942 3.8291 11.332 6.52148 11.332Z" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.4792 12.4167L10.3125 10.25" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <input
          v-model="searchText"
          type="text"
          placeholder="Search"
          class="w-full bg-transparent outline-none py-2.5"
          @focus="isSearchActive = true"
          @blur="handleSearchBlur"
        />
        <template v-if="isSearchActive">
          <span class="cursor-pointer" @click="handleSearchClose">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10.623 10L13.8428 6.78027C14.1357 6.4873 14.1357 6.0127 13.8428 5.71973C13.5498 5.42676 13.0752 5.42676 12.7822 5.71973L9.5625 8.93945L6.34277 5.71973C6.0498 5.42676 5.5752 5.42676 5.28223 5.71973C4.98926 6.0127 4.98926 6.4873 5.28223 6.78027L8.50195 10L5.28223 13.2197C4.98926 13.5127 4.98926 13.9873 5.28223 14.2803C5.42871 14.4268 5.62061 14.5 5.8125 14.5C6.00439 14.5 6.19629 14.4268 6.34277 14.2803L9.5625 11.0605L12.7822 14.2803C12.9287 14.4268 13.1206 14.5 13.3125 14.5C13.5044 14.5 13.6963 14.4268 13.8428 14.2803C14.1357 13.9873 14.1357 13.5127 13.8428 13.2197L10.623 10Z" fill="#4A4A4A"/>
            </svg>
          </span>
        </template>
      </div>
      <!-- Search End -->
    </div>
    <!-- Filter and actions -->
    <div
      class="flex items-center justify-between h-[4.25rem] bottom-border-only px-[13px] gap-[14px]"
    >
      <div class="flex items-center gap-[6px]">
        <Checkbox
          :model-value="isAllSelected"
          :indeterminate="isIndeterminate"
          @update:model-value="toggleSelectAll"
        />

        <Dropdown placement="bottom" dropdown-classes="!min-w-[100px]" button-classes="border-none hover:!border-none !px-0 !gap-0">
          <template v-slot:selected><span></span></template>
          <DropdownItem size="sm" @click="selectByStatus('all')">All</DropdownItem>
          <DropdownItem size="sm" @click="selectByStatus('read')">Read</DropdownItem>
          <DropdownItem size="sm" @click="selectByStatus('unread')">Unread</DropdownItem>
        </Dropdown>
      </div>
      <template v-if="showBulkActions">
        <transition name="fade" mode="out-in">
          <div class="flex flex-1 items-center gap-[4px]">
            <Dropdown
              v-if="shouldShowBulkActions(routeBasedBulkActionsKeys.assign)"
              :disabled="disabledBulkActions"
              placement="bottom"
              dropdown-classes="!min-w-[250px]"
              button-classes="border-none hover:!border-none !text-sm"
              >
              <template v-slot:selected>
                <img
                  :src="inboxAssignTo"
                  alt="assign"
                />
                  Assign To
              </template>
              <template v-if="isAssigning" v-slot:arrow>
                <clip-loader
                  class="align-middle inline-block"
                  color="#4165ed"
                  :size="'16px'"
                ></clip-loader>
              </template>
              <DropdownItem
                size="sm"
                class="flex items-center justify-between group"
                @click="updateConversationsStatuses('assigned', false, null)"
              >
                <div class="flex items-center gap-2 ">
                  <img
                    :src="`https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
                    alt="avatar"
                    class="w-8 h-8 rounded-full"
                  />
                  Unassign
                </div>
                <img
                  :src="inboxTickMark"
                  alt="selected list icon"
                  class=" hidden group-hover:block"
                />
              </DropdownItem>
              <DropdownItem
                v-for="member in getters.getWorkspaceInboxTeamMembers"
                :key="member.user._id"
                size="sm"
                class="flex items-center justify-between group"
                @click="updateConversationsStatuses('assigned', true, member.user)"
              >
                <div class="flex items-center gap-2 ">
                  <img
                    :src="member.user?.image || `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
                    alt="avatar"
                    class="w-8 h-8 rounded-full"
                    @error="() => {
                      $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                    }"
                  />
                  {{ member.user?.full_name }}
                </div>
                <img
                  :src="inboxTickMark"
                  alt="selected list icon"
                  class=" hidden group-hover:block"
                />
              </DropdownItem>
            </Dropdown>
            <Button
              v-if="shouldShowBulkActions(routeBasedBulkActionsKeys.markAsDone)"
              variant="subtle"
              color="success"
              size="sm"
              class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
              :disabled="disabledBulkActions"
              @click="updateConversationsStatuses('marked_done', true, null)"
            >
              <img
                :src="inboxMarkDone"
                alt="done"
              />
              Mark as Done
              <template v-if="isMarkingAsDone" >
                <clip-loader
                  class="align-middle inline-block"
                  color="#4165ed"
                  :size="'16px'"
                ></clip-loader>
              </template>
            </Button>
            <Button
              v-if="shouldShowBulkActions(routeBasedBulkActionsKeys.markAsUndone)"
              variant="subtle"
              color="success"
              size="sm"
              class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
              :disabled="disabledBulkActions"
              @click="updateConversationsStatuses('marked_done', false, null)"
            >
              <img
                :src="inboxMarkDone"
                alt="done"
              />
              Mark as Undone
              <template v-if="isMarkingAsDone" >
                <clip-loader
                  class="align-middle inline-block"
                  color="#4165ed"
                  :size="'16px'"
                ></clip-loader>
              </template>
            </Button>
            <Button
              v-if="shouldShowBulkActions(routeBasedBulkActionsKeys.archive)"
              variant="subtle"
              color="warning"
              size="sm"
              class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
              :disabled="disabledBulkActions"
              @click="updateConversationsStatuses('archived', true, null)"
            >
              <img
                :src="inboxArchived"
                alt="archive"
              />
              Archive
              <template v-if="isArchiving" >
                <clip-loader
                  class="align-middle inline-block"
                  color="#4165ed"
                  :size="'12px'"
                ></clip-loader>
              </template>
          </Button>
          <Button
            v-if="shouldShowBulkActions(routeBasedBulkActionsKeys.unarchive)"
            variant="subtle"
            color="warning"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="disabledBulkActions"
            @click="updateConversationsStatuses('archived', false, null)"
          >
            <img
              :src="inboxArchived"
              alt="archive"
            />
            Unarchive
            <template v-if="isArchiving" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'12px'"
              ></clip-loader>
            </template>
          </Button>
          </div>
        </transition>
      </template>
      <template v-else>
        <div class="flex-1 flex items-center gap-[4px]">
          <template v-for="(item, index) in inboxTypes" :key="index">
            <div
              :class="selectedInboxType === item.value.toLowerCase() ? 'cstu-text-blue-500 cstu-bg-blue-50 font-medium' : ''"
              class="select-none text-sm rounded-full py-[5.5px] px-[10px] flex items-center hover:cstu-text-blue-500 cursor-pointer "
              @click="handleInboxTypeChange(item.value.toLowerCase())"
              >
                {{ item.label }}
            </div>
          </template>
        </div>
        <div class="flex items-center">
          <Dropdown placement="bottom" dropdown-classes="!min-w-[150px]" button-classes="border-none hover:!border-none !px-0 !gap-0">
            <template v-slot:selected>
              <span v-tooltip="'Sort by'">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                  <path d="M0.867188 1.65002C0.867188 1.23581 1.20297 0.900024 1.61719 0.900024H15.1172C15.5314 0.900024 15.8672 1.23581 15.8672 1.65002C15.8672 2.06423 15.5314 2.40002 15.1172 2.40002H1.61719C1.20297 2.40002 0.867188 2.06423 0.867188 1.65002Z" fill="#4A4A4A"/>
                  <path d="M3.11719 5.40002C3.11719 4.98581 3.45298 4.65002 3.86719 4.65002H12.8672C13.2814 4.65002 13.6172 4.98581 13.6172 5.40002C13.6172 5.81425 13.2814 6.15002 12.8672 6.15002H3.86719C3.45298 6.15002 3.11719 5.81425 3.11719 5.40002Z" fill="#4A4A4A"/>
                  <path d="M6.86719 8.40002C6.45298 8.40002 6.11719 8.7358 6.11719 9.15002C6.11719 9.56425 6.45298 9.90002 6.86719 9.90002H9.86719C10.2814 9.90002 10.6172 9.56425 10.6172 9.15002C10.6172 8.7358 10.2814 8.40002 9.86719 8.40002H6.86719Z" fill="#4A4A4A"/>
                </svg>
              </span>
            </template>
            <template v-slot:arrow>
              <span></span>
            </template>
            <DropdownItem
              v-for="option in convoSortingOptions"
              :key="option.value"
              size="sm"
              class=" flex items-center justify-between"
              :selected="selectedConversationSort === option.value"
              @click="handleConversationSortChange(option.value)"
            >
              {{ option.label }}
              <template v-if="selectedConversationSort === option.value">
                <img
                  :src="inboxTickMark"
                  alt="selected list icon"
                />
              </template>
            </DropdownItem>
          </Dropdown>
        </div>
      </template>

    </div>
    <!-- Message List -->
    <div
      v-infinite-scroll="[loadMoreItems, { distance: 30 }]"
      class="overflow-y-auto flex-1"
    >
      <template v-if="isConversationFetching && pageNumber === 1">
        <div class="flex flex-col gap-1">
          <div v-for="i in 5" :key="i">
            <SkeletonBox
              width="100%"
              height="140px"
            />
          </div>
        </div>
      </template>
      <template v-else >
        <template v-for="(item) in listItems" :key="item.dynamicId">
          <InboxListItem
            :id="`message-item-${item?._id}`"
            :is-unread="item.isUnread && !item.isActive"
            :is-active="item.isActive"
            :show-item-type="selectedInboxType === 'all'"
            :item="item"
            :is-checked="selectedConversationListItems.includes(item.element_details.element_id)"
            @item-click="onItemClick(item)"
            @checkbox-toggle="(value) => {
              selectListItem(value, item)
            }"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import { SegmentedControl, Checkbox, Dropdown, DropdownItem, Button } from '@contentstudio/ui'
import InboxListItem from '@src/modules/inbox-revamp/components/InboxListItem.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import { vInfiniteScroll } from '@vueuse/components'
import { useRouter } from 'vue-router'
import { debounce } from 'lodash'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import { useStore } from '@state/base'
import TeamMembersSelect from '@src/components/common/TeamMembersSelect.vue'

const router = useRouter()
const { getters } = useStore()
const {
  inboxTypes,
  selectedInboxType,
  conversationList,
  currentConversation,
  isConversationFetching,
  pageNumber,
  selectedConversationListItems,
  convoSortingOptions,
  selectedConversationSort,
  searchText,
  isAssigning,
  isArchiving,
  isMarkingAsDone,
  routeBasedFiltersKeys,
  routeBasedFilters,
  routeBasedBulkActions,
  routeBasedBulkActionsKeys,
  selectedAssingedTo,
  selectedAssingedBy,
  selectedMarkedDoneBy,
  selectedArchivedBy,
  markAsDoneInboxItems,
  archiveInboxItems,
  assignInboxToUser,
  fetchDetails,
  fetchConversationList,
} = useInbox()

const { inboxMarkDone, inboxTickMark, inboxArchived, inboxAssignTo } = useIcons()

const segmentedConvoFilter = ref('all')

const listItems = computed(() => {
  const conversations = conversationList.value?.conversations || [];
  const mapItem = (item, index) => ({
    ...item,
    index,
    isUnread: item.element_details.read_status === 'unread',
    isActive: item._id === currentActiveMessage.value,
    size: 140,
    dynamicId: `${item._id}_${segmentedConvoFilter.value}`
  });

  if (segmentedConvoFilter.value === 'all') {
    return conversations.map(mapItem);
  } else {
    return conversations
      .filter(item => item.element_details.read_status === segmentedConvoFilter.value)
      .map(mapItem);
  }
})

const isAllSelected = computed(() => {
  return listItems.value.length > 0 && selectedConversationListItems.value.length === listItems.value.length
})

const isIndeterminate = computed(() => {
  return selectedConversationListItems.value.length > 0 && selectedConversationListItems.value.length < listItems.value.length
})

const currentViewRoute = computed(() => {
  return router.currentRoute.value?.params?.filter || 'all'
})

const currentActiveMessage = computed(() => {
  return router.currentRoute.value?.params?.conversation
})

const unreadCount = computed(() => {
  return conversationList.value?.conversations?.filter(item => item.element_details.read_status === 'unread').length || 0
})

const showBulkActions = computed(() => {
  return selectedConversationListItems.value.length > 0
})

const currentRouteBasedFilters = computed(() => {
  return routeBasedFilters?.[currentViewRoute.value]
})

const currentRouteBasedBulkActions = computed(() => {
  return routeBasedBulkActions?.[currentViewRoute.value]
})

const routeTitle = computed(() => {
  return currentViewRoute.value.replace(/[-_]/g, ' ')
})

const disabledBulkActions = computed(() => {
  return isArchiving.value || isMarkingAsDone.value || isAssigning.value
})
const isSearchActive = ref(false)

const handleSearchBlur = () => {
  if (!searchText.value) {
    isSearchActive.value = false
  }
}

const handleSearchClose = () => {
  searchText.value = ''
  isSearchActive.value = false
}

watch(currentConversation , (value) => {
  if (value) {
    document.getElementById(`message-item-${value._id}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
})

watch(searchText, (newVal, oldVal) => {
  console.log('WATCH::searchText ~ newVal, oldVal -> ', newVal, oldVal)
  if (newVal !== oldVal) {
    fetchDetailsDebounced(selectedInboxType.value)
  }
})

const handleInboxTypeChange = (value) => {
  selectedInboxType.value = value
  fetchDetailsDebounced(value)
}

const handleConversationSortChange = (value) => {
  selectedConversationSort.value = value
  fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
}

const fetchDetailsDebounced = debounce(async (value) => {
  fetchDetails(inboxTypes?.[value]?.filter)
  await fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, router?.currentRoute.value?.params?.filter)
  console.log('fetchDetailsDebounced', conversationList.value)
}, 500)

const onItemClick = (item) => {
  currentConversation.value = item
  router.push({ name: 'inbox-revamp-conversation', params: { conversation: item._id, filter: currentViewRoute.value } })
}

const loadMoreItems = async () => {
  if (!isConversationFetching.value && pageNumber.value < conversationList.value?.lastPage) {
    pageNumber.value++
    await fetchConversationList(
      inboxTypes?.[selectedInboxType.value]?.filter,
      router?.currentRoute.value?.params?.filter,
      pageNumber.value,
      true
    )
  }
}

const selectListItem = (checked, item) => {
  if (checked) {
    selectedConversationListItems.value.push(item?.element_details?.element_id)
  } else {
    const index = selectedConversationListItems.value.indexOf(item?.element_details?.element_id)
    if (index > -1) {
      selectedConversationListItems.value.splice(index, 1)
    }
  }
}

const toggleSelectAll = () => {
  if (!isAllSelected.value) {
    selectedConversationListItems.value = listItems.value.map(item => item?.element_details?.element_id)
  } else {
    selectedConversationListItems.value = []
  }
}

const selectByStatus = (status) => {
  if (status === 'all') {
    selectedConversationListItems.value = listItems.value.map(item => item?.element_details?.element_id)
  }
  else if (status === 'read') {
    selectedConversationListItems.value = listItems.value.filter(item => item.element_details.read_status === 'read').map(item => item?.element_details?.element_id)
  }
  else if (status === 'unread') {
    selectedConversationListItems.value = listItems.value.filter(item => item.element_details.read_status === 'unread').map(item => item?.element_details?.element_id)
  }
}

const updateConversationsStatuses = async (action, value, user) => {
  switch (action) {
    case 'assigned':
      await assignInboxToUser(value, {
        user_id: user?._id || '',
        user_name: user?.full_name || '',
      })
      fetchDetailsDebounced(selectedInboxType.value)
      break
    case 'marked_done':
      await markAsDoneInboxItems(value)
      fetchDetailsDebounced(selectedInboxType.value)
      break
    case 'archived':
      await archiveInboxItems(value)
      fetchDetailsDebounced(selectedInboxType.value)
      break
    default:
      break
  }
}

const updateAssingedTo = (value) => {
  selectedAssingedTo.value = value
  fetchDetailsDebounced(selectedInboxType.value)
}

const updateAssingedBy = (value) => {
  selectedAssingedBy.value = value
  fetchDetailsDebounced(selectedInboxType.value)
}

const updateMarkedDoneBy = (value) => {
  selectedMarkedDoneBy.value = value
  fetchDetailsDebounced(selectedInboxType.value)
}

const updateArchivedBy = (value) => {
  selectedArchivedBy.value = value
  fetchDetailsDebounced(selectedInboxType.value)
}

const shouldShowFilter = (key) => {
  return currentRouteBasedFilters.value.includes(key)
}

const shouldShowBulkActions = (key) => {
  return currentRouteBasedBulkActions.value.includes(key)
}

</script>
